import React, { useEffect, useRef, useState } from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import "react-multi-carousel/lib/styles.css";
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogSection from "../../component/Blogsection";
import CompanyVideo from "../../asset/video/company-video.mp4";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import SolutionCarousel from "../../component/SolutionCarousel";
import IndustryCard from "../../component/IndustryCard";
import Btn from "../../component/Btn";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BannerFaceDetectionVideo from "../../asset/video/Facial-Regagonisation.mp4"
import BannerPersonObjectDetectionVideo from "../../asset/video/Person-Object-Tracking.mp4"
import BannerLossprevention from '../../asset/video/loss_prevention.mp4'
import BannerVisionAnalystics from '../../asset/video/vision_analytics.mp4'
import TestimonialSection from "../../component/TestimonialSection";

const Home = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [currentImage, setCurrentImage] = useState(0);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [industry, setIndustry] = useState('retail');
    const [progress, setProgress] = useState(0);

    const thumbnail = [PATH.img.thumbnail_loss_prevention, PATH.img.thumbnail_vision_analytics, PATH.img.thumbnail_face_recognition, PATH.img.thumbnail_person_object_tracking,]
    const images1 = [PATH.img.CircleLossprevention, PATH.img.vision_analystics_img, PATH.img.AiattendanceImg, PATH.img.CircleObjecttracking,];
    const videos = [BannerLossprevention, BannerVisionAnalystics, BannerFaceDetectionVideo, BannerPersonObjectDetectionVideo,]

    const details = [
        { heading: 'Loss Prevention', para: 'Track the store, Deter theft and shrink with proactive solutions.' },
        { heading: 'Vision Analytics', para: 'Decode the scene with precise analytics on heat maps, behavior, and traffic patterns for smarter decisions.' },
        { heading: 'Face Recognition', para: 'Scan the Face, reveal and confirm identities with pinpoint accuracy.' },
        { heading: 'Person and Object Tracking', para: 'Spot and identify every person and object in every frame.' },

    ];

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: isMobileView ? 1 : 3,
        slidesToScroll: 1,
    };
    const videoRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    setCurrentImage((prevImage) => (prevImage + 1) % videos.length);
                    return 0;
                }
                return prevProgress + 1;
            });
        }, 120);

        return () => {
            clearInterval(interval);
        };
    }, [videos.length]);


    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setScrollPosition(scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.src = videos[currentImage];
            videoRef.current.play();
        }
        setProgress(0);
    }, [currentImage]);

    const calculateTransform = () => {
        const translateY = -100 + scrollPosition / 5;
        const translateX = -100 + scrollPosition / 5;
        const translateZ = 70 + scrollPosition / 5;
        return `translateY(${translateY}px) translateX(${translateX}px) translateZ(${translateZ}px)`;
    };

    const handleNextSlide = () => {
        setCurrentImage((prevImage) => (prevImage + 1) % videos.length);
    };

    const handleBackSlide = () => {
        setCurrentImage((prevImage) => (prevImage - 1 + videos.length) % videos.length);
    };


    function scrollDownBy() {
        window.scrollBy(0, 530);
    }
    return (
        <React.Fragment>
            <div id="scroll_main" className="overflow-hidden">
                <div className="home_banner relative">
                    <video ref={videoRef} className="h-screen w-screen object-cover opacity-100" style={{ backgroundImage: `url(${thumbnail[currentImage]})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', objectFit: 'cover', width: '100vw', height: '100vh' }} playsInline autoPlay={true} muted loop>
                        <source src={videos[currentImage]} type="video/mp4" className="transition-opacity duration-1000 ease-in-out  " />
                    </video>
                    <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 h-full w-full object-cover active" />
                    <div className="home_banner_content">
                        <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto">
                            <div>
                                <div className="mb-8 lg:mb-14 z-30">
                                    <h1 className="text-2xl lg:text-4xl fB text-white">Forge Ahead Smarter with<br />Artificial Intelligence</h1>
                                    <p className="text-lg max-w-xl fR text-white mt-4">Pioneering Futuristic Vision AI Software and Enterprise Solutions</p>
                                </div>
                                <button className="fR text-sm text-white border-secondary border rounded-full px-2 py-1 mb-14 md:mb-36  lg:mb-14" onClick={() => window.location.reload()}>
                                    <Link className="flex items-center gap-8" to={('/contact')}>
                                        <p className="pl-2">Request Demo</p>
                                        <ArrowLongRightIcon className="w-8 h-8 bg-gradient-to-br from-primary from-30% to-secondary to-90% rounded-full p-1" style={{ color: "#fff" }} />
                                    </Link>
                                </button>
                            </div>
                            <div className="relative lg:flex justify-end">
                                <div className="count-circle">
                                    <div className="flex gap-2 flex-row flex-row-reverse lg:flex-row items-start">
                                        <div className="flex flex-col justify-start lg:justify-end">
                                            <h1 className="fB text-xl lg:text-end text-white mb-2">{details[currentImage].heading}</h1>
                                            <p className="fR text-sm text-white lg:text-end max-w-sm">{details[currentImage].para}</p>
                                        </div>
                                        <div>
                                            <CircularProgressbar
                                                value={progress}
                                                // text={`${currentImage + 1}`}
                                                styles={buildStyles({
                                                    textColor: "#fff",
                                                    pathColor: "#F89B60",
                                                    trailColor: "#fff",
                                                    strokeLinecap: "butt",
                                                    textSize: "40px"
                                                })}
                                                strokeWidth={50}

                                                style={{ path: { transformOrigin: 'center center' } }}
                                                className="w-10 h-10 border-4  border-gray-50 lg:w-14 lg:h-14 fB rounded-full flex justify-center items-center"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="relative ">
                                    <div className="ripple hidden lg:block" style={{ backgroundImage: `url(${images1[currentImage]})`, objectFit: "cover", backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                    <div className="absolute -bottom-36 md:-bottom-10 lg:-bottom-20  lg:right-[25%] lg:left-[25%] text-white  z-30">
                                        <div className="flex justify-center items-center">
                                            <div className="flex justify-center items-center pr-2">
                                                <p className="fM fs-28">{currentImage + 1}</p>
                                                <p className="fR fs-20">/4</p>
                                            </div>
                                            <div onClick={handleBackSlide}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-9 cursor-pointer">
                                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-4.28 9.22a.75.75 0 0 0 0 1.06l3 3a.75.75 0 1 0 1.06-1.06l-1.72-1.72h5.69a.75.75 0 0 0 0-1.5h-5.69l1.72-1.72a.75.75 0 0 0-1.06-1.06l-3 3Z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <div onClick={handleNextSlide}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-9 cursor-pointer" >
                                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="body_content" id="content-div">
                    <div className="section2 container mx-auto relative py-6 lg:py-24">
                        <div className="hidden md:block">
                            <img src={PATH.img.Bgcircle} alt="circle" className="z-[-1] bg-circle" style={{ transform: calculateTransform() }} />
                        </div>
                        <div className="w-full">
                            <h2 className="text-xl md:text-3xl fB text-hColor mb-4 lg:mb-8 text-center">Steadfast Partner in Your Digital Transformation Journey</h2>
                            <p className="text-base fR text-[#7E7E7E] mb-2 lg:max-w-5xl text-center mx-auto">As your trusted ally, We harness Artificial Intelligence and App development expertise to drive unparalleled digital success. With Visual AI providing real-time insights and personalized recommendations, elevate customer engagement effortlessly into a new era of business excellence.</p>
                        </div>
                    </div>

                    <section className="container mx-auto">
                        <div className="lg:flex gap-16 align-middle items-center py-4 lg:py-16">
                            <div className="flex-1 relative">
                                <img src={PATH.img.about} className="rounded-2xl h-full w-full object-cover mb-4 lg:m-0" alt="company-img" />
                                <button className="w-14 h-14 bg-white border-rose-500 absolute top-[50%] bottom-[50%] -right-6 rounded-full flex justify-center items-center">
                                    <ArrowUpRightIcon className="w-8 h-8" />
                                </button>
                            </div>
                            <div className="flex-1">
                                <h2 className="fB text-lg mb-4">What Drives Us</h2>
                                <p className="fR text-md">Proglint is a top-tier provider of advanced AI, machine learning, and computer vision solutions addressing complex industry-specific needs with precision. We offer end-to-end services from initial pilot programs to full-scale production, ensuring strategic guidance and advanced engineering for successful AI implementation. With 300+ Entrepreneurs consulted and 225+ projects delivered globally, we drive business transformation and deliver impactful results through innovative technology.</p>
                                <div className="flex gap-10 py-12">
                                    <div className="flex flex-col gap-1">
                                        <p className="fB text-5xl">6+</p>
                                        <p className="fB text-base">Years of existence</p>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p className="fB text-5xl">100+</p>
                                        <p className="fB text-base">Tech Wizards</p>
                                    </div>
                                </div>
                                <Btn LinkPath={('/company')} value={'View More'} bgColor={'white'} />
                            </div>
                        </div>
                    </section>
                    <section className="bg-[#03010A] py-16">
                        <div className="container mx-auto flex flex-col gap-12">
                            <div className="title block lg:flex justify-between">
                                <div>
                                    <div className="mb-10">
                                        <h2 className="text-2xl fB mb-1 text-white">The Future is Now</h2>
                                        <h2 className="text-3xl fB text-[#9F9F9F]">We Reimagine How Every Industry Operates.</h2>
                                    </div>
                                    <Btn LinkPath={('/research')} value={'Learn About Proglint Research'} bgColor={'black'} />
                                </div>
                                <p className="fR text-md max-w-xl mt-6 lg:mt-0 text-[#969696]">Proglint bridges industries to Digital Transformation, unlocking new horizons with avant-garde AI solutions. Our profound industry expertise ensures groundbreaking, bespoke Visual AI implementations. Trust Proglint to redefine your business with innovative AI.</p>
                            </div>
                            <div className="lg:flex gap-16">
                                <div className="lg:flex-1">
                                    <ul className="flex gap-4 mb-8 overflow-auto lg:block">
                                        <li className={`cursor-pointer  flex gap-10 items-center lg:border-b ${industry == 'retail' ? 'text-white' : 'text-[#4A4A4A]'} border-[#7f7f7f36] lg:py-6 lg:px-4`} onClick={() => setIndustry('retail')}><p className="hidden lg:block lg:text-lg fR">01</p><p className="text-xl border px-4 py-2 rounded-full lg:border-none lg:text-2xl fSB">Retail</p><ArrowLongRightIcon className={`hidden lg:block w-16 h-8 ${industry == 'retail' ? 'text-white' : 'text-[#4A4A4A]'}`} /></li>
                                        <li className={`cursor-pointer  flex gap-10 items-center lg:border-b ${industry == 'education' ? 'text-white' : 'text-[#4A4A4A]'} border-[#7f7f7f36] lg:py-6 lg:px-4`} onClick={() => setIndustry('education')}><p className="hidden lg:block lg:text-lg fR">02</p><p className="text-xl border px-4 py-2 rounded-full lg:border-none lg:text-2xl fSB">Education</p><ArrowLongRightIcon className={`hidden lg:block w-16 h-8 ${industry == 'education' ? 'text-white' : 'text-[#4A4A4A]'}`} /></li>
                                        <li className={`cursor-pointer  flex gap-10 items-center lg:border-b ${industry == 'manufactur' ? 'text-white' : 'text-[#4A4A4A]'} border-[#7f7f7f36] lg:py-6 lg:px-4`} onClick={() => setIndustry('manufactur')}><p className="hidden lg:block lg:text-lg fR">03</p><p className="text-xl border px-4 py-2 rounded-full lg:border-none lg:text-2xl fSB">Manufacturing</p><ArrowLongRightIcon className={`hidden lg:block w-16 h-8 ${industry == 'manufactur' ? 'text-white' : 'text-[#4A4A4A]'}`} /></li>
                                        <li className={`cursor-pointer  flex gap-10 items-center lg:border-b ${industry == 'healthcare' ? 'text-white' : 'text-[#4A4A4A]'} border-[#7f7f7f36] lg:py-6 lg:px-4`} onClick={() => setIndustry('healthcare')}><p className="hidden lg:block lg:text-lg fR">04</p><p className="text-xl border px-4 py-2 rounded-full lg:border-none lg:text-2xl fSB">Healthcare</p><ArrowLongRightIcon className={`hidden lg:block w-16 h-8 ${industry == 'healthcare' ? 'text-white' : 'text-[#4A4A4A]'}`} /></li>
                                    </ul>
                                </div>
                                {industry == 'retail' &&
                                    <div className="flex flex-auto flex-col gap-10">
                                        <IndustryCard
                                            ImgPath={PATH.img.industry_loss_prevention}
                                            Title={'Loss Prevention'}
                                            Subtext={'Smart Checkout, Smarter Security. Instantly detects mismatches, fake scans, and abandoned carts with real-time alerts, while optimizing store flow and unlocking priceless shopper insights.  we expertly manage store flow, analyze shopper behavior, and uncover invaluable product insights.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/loss-prevention')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </Link>}
                                        />

                                        <IndustryCard
                                            ImgPath={PATH.img.industry_vision_analytics}
                                            Title={'Vision Analytics'}
                                            Subtext={'Go beyond interactions - gain real-time insights from customer journeys, displayed on a user-friendly dashboard empowering you to make data-driven decisions. Transform your business with actionable and insightful intelligence.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/vision-analytics')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </Link>}
                                        />
                                        <IndustryCard
                                            ImgPath={PATH.img.industry_action_recognition}
                                            Title={'Action Recognition'}
                                            Subtext={'AI that understands human behavior.Achieve top-notch customer satisfaction, prevent theft, and safeguard patient safety with real-time behavior insights like never before.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/action-recognition')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </Link>}
                                        />
                                        <Btn LinkPath={('/industry/retail')} value={'View More'} bgColor={'black'} />

                                    </div>
                                }
                                {industry == 'education' &&
                                    <div className="flex flex-auto flex-col gap-10">
                                        <IndustryCard
                                            ImgPath={PATH.img.industry_ai_attendance}
                                            Title={'AI Attendance'}
                                            Subtext={'With "Your Face is Your Pass," our AI-driven attendance system transforms classroom management. Just walk in, get verified, and proceed. Our intelligent person tracking ensures real-time notifications, robust security, and reliable data integrity while safeguarding student privacy and preventing falsification.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/ai-attendance')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </ Link>}
                                        />

                                        <IndustryCard
                                            ImgPath={PATH.img.industry_asset_tracking}
                                            Title={'SmartEdu Asset Solutions'}
                                            Subtext={'Seamlessly Manage School Essentials with Smart Tracking and Transform Your Inventory with 360° Robot Educational Asset Management. Keep school essentials, books, and other valuable resources in check with our asset tracking, ensuring every item finds its place without a trace.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/asset-tracking')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </Link>}
                                        />
                                        <IndustryCard
                                            ImgPath={PATH.img.industry_safety_security}
                                            Title={'Campus Safeguarding'}
                                            Subtext={'Achieve serene campus safety with AI-powered security. Our intelligent systems offer 24/7 protection, detecting potential threats and unauthorized access without disrupting the school environment. Plus, they monitor traffic and parking lot flow, enhancing campus safety and efficiency with precision.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/safety-security')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </Link>}
                                        />
                                        <Btn LinkPath={('/industry/education')} value={'View More'} bgColor={'black'} />
                                    </div>
                                }
                                {industry == 'manufactur' &&
                                    <div className="flex flex-auto flex-col gap-10">
                                        <IndustryCard
                                            ImgPath={PATH.img.industry_quality_control}
                                            Title={'Quality Control'}
                                            Subtext={'Revolutionize quality control with AI-powered precision. Detect flaws, optimize processes, and ensure product perfection with our advanced system. See beyond the surface, uncover hidden defects, and deliver unmatched quality, and your products are perfected.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/ai-for-quality-control')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </ Link>}
                                        />
                                        <IndustryCard
                                            ImgPath={PATH.img.industry_object_product}
                                            Title={'Object/Product Counting'}
                                            Subtext={'Precisely count and classify every item with AI-powered vision. Our advanced computer vision technology counts and tracks products effortlessly on the assembly line, ensuring real-time accuracy and minimizing errors. '}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/asset-tracking')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </Link>}
                                        />
                                        <IndustryCard
                                            ImgPath={PATH.img.industry_safety_security}
                                            Title={'Safety and Security'}
                                            Subtext={'In manufacturing, safety isn’t just a goal—it’s a standard. Our advanced computer vision system ensures your team’s protection by spotting production flaws, verifying safety gear, and delivering real-time insights. From helmet checks to safety compliance, we turn vigilance into a seamless part of your workflow.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/safety-security')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </Link>}
                                        />
                                        <Btn LinkPath={('/industry/manufacturing')} value={'View More'} bgColor={'black'} />
                                    </div>
                                }
                                {industry == 'healthcare' &&
                                    <div className="flex flex-auto flex-col gap-10">
                                        <IndustryCard
                                            ImgPath={PATH.img.industry_people_counting}
                                            Title={'People Counting'}
                                            Subtext={'See Your Healthcare Space Differently with People Counting. Uncover foot traffic secrets, analyze visitor patterns, monitor flow dynamics, and assess dwell zones to boost efficiency and ensure precise social distancing.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions-loss-prevention')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </ Link>}
                                        />

                                        <IndustryCard
                                            ImgPath={PATH.img.industry_medical_image_analysis}
                                            Title={'Medical Image Analysis'}
                                            Subtext={'Precisely analyze medical images, accelerate diagnosis, and improve patient outcomes. It excels in processing images like X-rays, and efficiently detecting fractures and other critical conditions. This cutting-edge technology enhances diagnostic accuracy and drives improved patient outcomes.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/medical-image-analysis')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </Link>}
                                        />
                                        <IndustryCard
                                            ImgPath={PATH.img.industry_pill_count}
                                            Title={'Pill Counting'}
                                            Subtext={'The Right Pill, the Right Count, Every Time. Revolutionize pharmaceutical automation with our advanced solution, combining kiosk, cloud, and edge applications for real-time monitoring. Ensuring patient safety and medication accuracy at every step.'}
                                            ProfileContent={<Link className="fR text-sm text-gray-200 mt-6 flex items-center gap-3" to={('/solutions/pill-counting')}><p>Go to Edge Ai  solutions</p><ArrowLongRightIcon className="w-5 h-5" style={{ color: "#fff" }} /> </Link>}
                                        />
                                        <Btn LinkPath={('/industry/healthcare')} value={'View More'} bgColor={'black'} />
                                    </div>
                                }
                            </div>

                        </div>
                    </section>

                    <section className="bg-[#F8F8F8] py-16">
                        <div className="container mx-auto flex flex-col gap-12">
                            <div className="title block lg:flex justify-between gap-12">
                                <div>
                                    <div className="mb-10">
                                        <h2 className="text-2xl fB mb-1">Proglint's Visual AI</h2>
                                        <h2 className="text-3xl fB text-[#C7C7C7]">Seeing Beyond, Shaping What's Next.</h2>
                                    </div>
                                    <Btn LinkPath={('/solution')} value={'Learn About Proglint Solutions'} bgColor={'white'} />
                                </div>
                                <p className="fR text-md max-w-xl mt-6 lg:mt-0">Proglint has always been at the forefront of innovation, continuously exploring new features to enhance business development with Visual AI and custom recommendations. Our state-of-the-art video intelligence drives remarkable revenue expansion across industries.</p>
                            </div>
                            <SolutionCarousel settings={settings} />
                        </div>
                    </section>
                    <section className="Success-story container mx-auto my-10 lg:my-24">
                        <div className="flex justify-center max-w-3xl flex-col mx-auto">
                            <h2 className="fR text-center text-xl lg:text-3xl mb-4">See the Future, Shape the Present with Proglint's Vision AI.</h2>
                        </div>
                        <div className="flex justify-center">
                            {/* <Btn LinkPath={('/')} value={'See all Client Results'} bgColor={'white'} /> */}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-10">
                            <div className="card relative bg-slate-300 py-20 h-96 rounded-md bg-cover bg-no-repeat bg-right" style={{ backgroundImage: `url(${PATH.img.FeaturesPersontracking})` }}>
                                <div className="absolute bottom-6 px-6 z-10">
                                    <div className="mb-4">
                                        <span className="fR fs-10 border text-gray-50 border-gray-50 rounded-full px-3 py-1">Features</span>
                                        <p className="text-lg fR mt-2 text-white">Person / Object Tracking </p>
                                    </div>
                                    <button className="fR text-xs text-gray-200 " onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/features/person-object-tracking')}><p>Learn More</p><ArrowUpRightIcon className="w-4 h-4" style={{ color: "#fff" }} /></Link></button>
                                </div>
                                <div className="absolute overflow-hidden rounded-md top-0 bg-gradient-to-t from-black  to-transparent w-full h-full"></div>
                            </div>
                            <div className="card md:mt-24 relative bg-slate-300 py-20 h-96 rounded-md bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${PATH.img.AiattendanceImg})` }}>
                                <div className="absolute bottom-6 px-6 z-10">
                                    <div className="mb-4">
                                        <span className="fR fs-10 border text-gray-50 border-gray-50 rounded-full px-3 py-1">Features</span>
                                        <p className="text-lg fR mt-2 text-white">Face Recognition</p>
                                    </div>
                                    <button className="fR text-xs text-gray-200 " onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/features/facial-recognition')}><p>Learn More</p><ArrowUpRightIcon className="w-4 h-4" style={{ color: "#fff" }} /></Link></button>
                                </div>
                                <div className="absolute overflow-hidden rounded-md top-0 bg-gradient-to-t from-black  to-transparent w-full h-full"></div>
                            </div>
                            <div className="card relative bg-slate-300 py-20 h-96 rounded-md bg-cover bg-no-repeat bg-top" style={{ backgroundImage: `url(${PATH.img.FeaturesShapeEstimation})` }}>
                                <div className="absolute bottom-6 px-6 z-10">
                                    <div className="mb-4">
                                        <span className="fR fs-10 border text-gray-50 border-gray-50 rounded-full px-3 py-1">Features</span>
                                        <p className="text-lg fR mt-2 text-white">Shape and Size Estimation</p>
                                    </div>
                                    <button className="fR text-xs text-gray-200 " onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/features/shape-size-estimation')}><p>Learn More</p><ArrowUpRightIcon className="w-4 h-4" style={{ color: "#fff" }} /></Link></button>
                                </div>
                                <div className="absolute overflow-hidden rounded-md top-0 bg-gradient-to-t from-black  to-transparent w-full h-full"></div>
                            </div>
                        </div>
                    </section>
                    <video className="h-full w-full" playsInline autoPlay={true} muted loop>
                        <source src={CompanyVideo} type="video/mp4" />
                    </video>
                    <BlogSection />
                    <TestimonialSection />
                </div>
                <FooterContact />
            </div>
        </React.Fragment>
    );
}

export default Home;
