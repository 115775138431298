import React from "react";

const Testimonial = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full text-center container mx-auto py-6 flex items-center justify-center">
                    <p className="white_banner_title fSB text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">Testimonial
                    </p>
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h1 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Dr. Ramalakshmi K</h1>
                <p className="text-base text-start text-pColor my-1 fB pt-2">Director of the Centre of Excellence in Computer Vision & Deputy Head, Department of Computer Science and Engineering, Alliance University.</p>
                <p className="text-base text-start text-pColor my-1 fR pt-2">"As the Director of the Centre of Excellence in Computer Vision and Deputy Head of the Department of Computer Science and Engineering at Alliance University, it is a pleasure to share our exceptional experience working with Proglint, a partner that has been instrumental in advancing our students’ futures and driving innovation within our institution.</p>
                <p className="text-base text-start text-pColor my-1 fR pt-2">Our collaboration with Proglint has opened up extraordinary opportunities for our university. Beyond establishing a cutting-edge Centre of Excellence lab, Proglint has provided invaluable hands-on experiences through internships and real-world projects, transforming our students' approach to learning and industry engagement.</p>
                <p className="text-base text-start text-pColor my-1 fR pt-2">Proglint’s commitment to fostering young talent has been evident in the dynamic hackathons that inspire creativity and critical problem-solving skills. The internship experiences they've facilitated have allowed our students to gain practical industry knowledge, seamlessly complementing their academic journey.</p>
                <p className="text-base text-start text-pColor my-1 fR">Additionally, Proglint’s involvement in developing and delivering industry-focused programs and workshops has been a true game-changer for us. These initiatives bridge the gap between theoretical knowledge and practical application, equipping our students to thrive in the modern workforce. The tailored workshops introduce the latest technologies, ensuring our students remain ahead in an ever-evolving field.</p>
                <p className="text-base text-start text-pColor my-1 fR pt-2  pb-5">I extend my deepest gratitude to Proglint for their steadfast support and dedication to our institution. The impact of this partnership has been profound, and I am confident that the skills and knowledge our students acquire will continue to serve them well throughout their careers. We eagerly look forward to further strengthening this partnership, as together, we shape the future of innovation and education."</p>
            </div>
        </div>
    )
}
export default Testimonial