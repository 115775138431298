import React from "react";
import { PATH } from "../globals/app-constants";
import Btn from "./Btn";

const TestimonialSection = () => {

    return (
        <div className="blog lg:py-14 py-4">
            <h1 className="fR text-center text-xl lg:text-3xl">Our Testimonial</h1>
            <section className="container mx-auto">
                <div className="lg:flex gap-28 align-top items-top py-4 lg:pt-16">
                    <div className="flex-1">
                        <h2 className="text-2xl fSB lg:mb-8 mb-5">Our Happy Client Say</h2>
                        <div className="flex lg:mb-8 mb-5">
                            <Btn LinkPath={('/testimonial')} value={'View More'} bgColor={'white'} />
                        </div>
                        <p className="lg:text-base fR mt-2 text-sm mb-8">"As the Director of the Centre of Excellence in Computer Vision and Deputy Head of the Department of Computer Science and Engineering at Alliance University, it is a pleasure to share our exceptional experience working with Proglint, a partner that has been instrumental in advancing our students’ futures and driving innovation within our institution."</p>
                    </div>
                    <div className="flex-1 relative">
                        <img src={PATH.img.TestimonailImg1} className="rounded-xl h-5/6 w-full object-cover mb-4 lg:m-0" alt="testimonial-img" />
                        <button className="w-64 h-32 bg-white border-rose-500 absolute lg:top-[45%] lg:bottom-[50%] lg:-left-10  bottom-[4%] left-16  rounded-xl flex justify-center items-center px-5 lg:shadow-xl testimonial-position">
                            <p className="text-xs fR"><span className="fB">Dr. Ramalakshmi K</span>, Director of the Centre of Excellence in Computer Vision & Deputy Head, Department of Computer Science and Engineering, Alliance University.</p>
                        </button>
                    </div>
                </div>
            </section>
            {/* <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto py-6 md:py-10">
                <div className="md:pr-6">
                    <h2 className="text-2xl fB mb-8">Proglint's Visual AI</h2>
                    <div className="flex mb-8">
                        <Btn LinkPath={('')} value={'View More'} bgColor={'white'} />
                    </div>
                    <p className="text-lg fR mt-2">"As the Director of the Centre of Excellence in Computer Vision and Deputy Head of the Department of Computer Science and Engineering at Alliance University, it is a pleasure to share our exceptional experience working with Proglint, a partner that has been instrumental in advancing our students’ futures and driving innovation within our institution."</p>
                </div>
                <div className="md:pl-6">
                    <img src={PATH.img.TestimonailImg1} alt="testimonial-img" className="object-cover rounded-xl" />
                </div>
            </div> */}

        </div>

    );
}
export default TestimonialSection