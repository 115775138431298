import React from "react";
import { PATH } from "../globals/app-constants";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard";
import Btn from "./Btn";

const BlogSection = () => {

    return (
        <div className="blog py-14">
            <h1 className="fR text-center text-xl lg:text-3xl">Our Latest Blog</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto py-6 md:py-10">
            <div className="md:border-r md:border-gray-300 md:pr-6">
                    <BlogCard
                        ImgPath={PATH.img.blogDetailImg13}
                        Date={'23 JUN 2024'}
                        Heading={'How Faster R-CNN is Shaping Object Detection Trends in 2024'}
                        ParaContent={'Faster R-CNN, a cornerstone in the field of Object detection, has seen significant advancements in 2024. Originally introduced in 2015, Faster R-CNN revolutionized object detection by integrating a Region Proposal Network (RPN) with the traditional Fast R-CNN, eliminating the need for external region proposal algorithms. Now, with the latest developments, Faster R-CNN continues to evolve, leveraging cutting-edge techniques in deep learning, hardware acceleration, and model optimization to meet the growing demands of real-time applications across diverse industries.'}
                        profileContent={
                            <>
                                <button className="card_find_btn my-5  underline text-xs fR text-hColor text-start" onClick={() => window.location.reload()}><Link to={('/blog/how-faster-rcnn-is-shaping-object-detection-trends')}>Find out more</Link></button>
                            </>
                        } />
                </div>
                <div className="md:pl-6">
                    <BlogCard

                        ImgPath={PATH.img.blogDetailImg12}
                        Date={'23 JUN 2024'}
                        Heading={'Computer Vision: Essential Techniques Guide 2024'}
                        ParaContent={'As technology continues to advance, Computer Vision has become a pivotal field, driving innovation across industries such as Healthcare, Retail, autonomous vehicles, and manufacturing. At its core, computer vision involves enabling machines to interpret and understand visual data from the world. This blog explores some of the essential techniques in computer vision for 2024, including image classification, segmentation, object detection, and more, delving into the technical methods behind these processes and their real-world applications.'}
                        profileContent={
                            <>
                                <button className="card_find_btn my-5  underline text-xs fR text-hColor text-start" onClick={() => window.location.reload()}><Link to={('/blog/computer-vision-essential-techniques-guide')}>Find out more</Link></button>
                            </>
                        } />
                </div>
            
            </div>
            <div className="flex justify-center">
                <Btn LinkPath={('/blog')} value={'See All Blogs'} bgColor={'white'} />
            </div>
        </div>

    );
}
export default BlogSection