import React from "react";
import Layout from "./component/Layout";
import Home from "./pages/Home";
import Company from "./pages/Company";
import Apps from "./pages/Apps";
import { ToastContainer } from 'react-toastify';
import { Routes, Route } from "react-router-dom";
import './asset/sass/style.scss'
import 'react-toastify/dist/ReactToastify.css';
import WebDevelopment from "./component/WebDevelopment";
import AppDevelopment from "./component/AppDevelopment";
import Aiml from "./component/AiMl";
import Product from "./pages/Product";
import Ios from './component/Ios'
import Android from "./component/Android";
import Hybrid from "./component/Hybrid";
import ReactNative from "./component/ReactNative";
import MEAN from "./component/MEAN";
import MERN from "./component/MERN";
import Java from "./component/Java";
import Python from "./component/Python";
import StaffAugmentation from "./component/StaffAugmentation";
import Career from "./pages/Career";
import CareerForm from "./component/Careerinputform";
import Contact from "./pages/Contact";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import Blogproject1 from "./pages/Blogscreen/Blogproject1";
import Blogproject2 from "./pages/Blogscreen/Blogproject2";
import Blogproject3 from "./pages/Blogscreen/Blogproject3";
import Blogproject4 from "./pages/Blogscreen/Blogproject4";
import Blogproject5 from "./pages/Blogscreen/Blogproject5";
import Blogproject6 from "./pages/Blogscreen/Blogproject6";
import Projects1 from "./pages/Clientstory/Projects1";
import Visioncomputing from "./component/VisionComputing";
import LossPrevention from "./pages/PortfolioDetails/LossPrevention";
import ALDIVisionCheckout from "./pages/PortfolioDetails/ALDIVisionCheckout";
import Cosfymart from "./pages/PortfolioDetails/Cosfymart";
import CVS from "./pages/PortfolioDetails/CVS";
import CVShield from "./pages/PortfolioDetails/CVShield";
import ShopApp from "./pages/PortfolioDetails/ShopApp";
import Equinox from "./pages/PortfolioDetails/Equinox";
import FreshersAll from "./pages/PortfolioDetails/FreshersAll";
import IntelVisionCheckout from "./pages/PortfolioDetails/IntelVisionCheckout";
import JedediahVisionCheckout from "./pages/PortfolioDetails/JedediahVisionCheckout";
import LeelaKitchen from "./pages/PortfolioDetails/LeelaKitchen";
import NourishBloom from "./pages/PortfolioDetails/NNB";
import PaulFashion from "./pages/PortfolioDetails/PaulFashion";
import ProductBox from "./pages/PortfolioDetails/ProductBox";
import QLM from "./pages/PortfolioDetails/QLM";
import SmartFridge from "./pages/PortfolioDetails/SmartFridge";
import TapAndGo from "./pages/PortfolioDetails/TapAndGo";
import Truex from "./pages/PortfolioDetails/Truex";
import PrivacyPolicy from "./pages/PrivacyPolicy"
import TermsOfService from "./pages/TermsOfService";
import Aladdin from "./pages/PortfolioDetails/Aladdin";
import Canteen from "./pages/PortfolioDetails/Canteen";
import Job2 from "./component/Job2";
import Job1 from "./component/Job1";
import Job3 from "./component/Job3";
import Job4 from "./component/Job4";
import Job5 from "./component/Job5";
import Job6 from "./component/Job6"
import PageNotFound from "./pages/404/404Page";
import Solutions from "./pages/Solutions";
import LP_Solution from './pages/Solutions/LP_Solution'
import Retail_Media from './pages/Solutions/Retail_Media'
import Unattended_Retail from './pages/Solutions/Unattended_Retail'
import Operational_Excellence from './pages/Solutions/Operational_Excellence'
import InRetail from "./pages/Industry/Retail";
import Research from "./pages/Research";
import ResearchDetail1 from "./pages/ResearchDetails/AI_application";
import Loss_Prevention_solution from './pages/Solutions/Loss_prevention';
import Pill_Counting_Solution from "./pages/Solutions/Pill_Counting";
import Vision_Analytics_Solution from "./pages/Solutions/Vision_Analytics";
import Asset_Tracking_Solution from "./pages/Solutions/Asset_Tracking";
import Safety_Security_Solution from "./pages/Solutions/Safety_Security";
import Industry_Retail from "./pages/Industry/Retail";
import Industry_Healthcare from "./pages/Industry/Healthcare"
import Industry_Education from "./pages/Industry/Education"
import Industry_Manufacturing from "./pages/Industry/Manufacturing"
import SolutionLanding from "./pages/Solutionlandingpage";
import FacialRecognition from "./pages/Features/FacialRecognition";
import AI_Attecndance from "./pages/Solutions/Ai_attendance";
import QualityControl from "./pages/Solutions/Quality_control";
import Vision_SOC from "./pages/Solutions/Vision_SOC";
import PersonObjectTracking from "./pages/Features/PersonObjectTracking";
import SpeedCalculation from "./pages/Features/SpeedCalculation";
import DistanceCalculation from "./pages/Features/DistanceCalculation";
import ShapeSizeEstimation from "./pages/Features/ShapeSizeEstimation";
import Medical_Image_Analysis from "./pages/Solutions/Medical_Image_Analysis";
import AIApplication from "./pages/ResearchDetails/AI_application";
import Smart_Fridge_Solution from "./pages/Solutions/Smart_Fridge";
import Action_Recognition_Solution from "./pages/Solutions/Action_Recognition";
import Action_Recognition from "./pages/Features/ActionRecognition";
import DeepStream from "./pages/ResearchDetails/NVIDIA_DeepStream";
import IntelDLStreamer from "./pages/ResearchDetails/Intel_DL_Streamer";
import EdgePlatform from "./pages/ResearchDetails/Intel_Tiber_Edge_Platform";
import BlogProject7 from "./pages/Blogscreen/BlogProject7";
import BlogProject8 from "./pages/Blogscreen/BlogProject8";
import BlogProject9 from "./pages/Blogscreen/BlogProject9";
import BlogProject10 from "./pages/Blogscreen/BlogProject10";
import BlogProject11 from "./pages/Blogscreen/BlogProject11";
import BlogProject12 from "./pages/Blogscreen/BlogProject12";
import BlogProject13 from "./pages/Blogscreen/BlogProject13";
import Testimonial from "./pages/Testimonial";


// ReactGA.initialize("UA-166271836-1");

const App = () => {

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });
  // }, []);

  return (
    <div className="w-full">
      <ToastContainer />
      <Routes>
        <Route path={'/'} element={<Layout />}>
          <Route path={'/'} element={<Home />}></Route>
          <Route path={'/company'} element={<Company />}></Route>
          <Route path={'/app-development-services'} element={<Apps />}></Route>
          <Route path={'/web-development-services'} element={<WebDevelopment />}></Route>
          <Route path={'/app-development'} element={<AppDevelopment />}></Route>
          <Route path={'/ai-development-services'} element={<Aiml />}></Route>
          <Route path={'/product-development-company'} element={<Product />}></Route>
          <Route path={'/iOS-app-development-company'} element={<Ios />}></Route>
          <Route path={'/android-app-development-services'} element={<Android />}></Route>
          <Route path={'/hybrid-app-development-services'} element={<Hybrid />}></Route>
          <Route path={'/react-native-app-development-services'} element={<ReactNative />}></Route>
          <Route path={'/mean-stack-development-services'} element={<MEAN />}></Route>
          <Route path={'/mern-stack-development-services'} element={<MERN />}></Route>
          <Route path={'/java-full-stack-development-services'} element={<Java />}></Route>
          <Route path={'/python-development-services'} element={<Python />}></Route>
          <Route path={'/staff-augmentation-services'} element={<StaffAugmentation />}></Route>
          <Route path={'/career'} element={<Career />}></Route>
          <Route path={'/career-form'} element={<CareerForm />}></Route>
          <Route path={'/contact'} element={<Contact />}></Route>
          <Route path={'/portfolio'} element={<Portfolio />}></Route>
          <Route path={'/blog'} element={<Blog />}></Route>
          <Route path={'/blog/harnessing-the-power-of-progressive-web-apps'} element={<Blogproject1 />}></Route>
          <Route path={'/blog/the-innovation-of-computer-vision-with-ai-ml'} element={<Blogproject2 />}></Route>
          <Route path={'/blog/emerging-trends-in-computer-vision'} element={<Blogproject3 />}></Route>
          <Route path={'/blog/video-analytics-for-predictive-ai-insights'} element={<Blogproject4 />}></Route>
          <Route path={'/blog/smart-retail-with-computer-vision'} element={<Blogproject5 />}></Route>
          <Route path={'/blog/next-gen-of-real-time-object-detection'} element={<Blogproject6 />}></Route>
          <Route path={'/blog/image-annotation-for-computer-vision-applications'} element={<BlogProject7 />}></Route>
          <Route path={'/blog/how-facial-recognition-is-revolutionizing-industries'} element={<BlogProject8 />}></Route>
          <Route path={'/blog/edge-computing-and-the-rise-of-edge-ai-in-computer-vision'} element={<BlogProject9 />}></Route>
          <Route path={'/blog/computer-vision-in-healthcare-diagnosing-diseases-with-ai'} element={<BlogProject10 />}></Route>
          <Route path={'/blog/computer-vision-impact-on-quality-control-and-defect-detection'} element={<BlogProject11 />}></Route>
          <Route path={'/blog/computer-vision-essential-techniques-guide'} element={<BlogProject12 />}></Route>
          <Route path={'/blog/how-faster-rcnn-is-shaping-object-detection-trends'} element={<BlogProject13 />}></Route>
          <Route path={'/client-story'} element={<Projects1 />}></Route>
          <Route path={'/computer-vision-development-services'} element={<Visioncomputing />}></Route>
          <Route path={'/case-studies/loss-prevention'} element={<LossPrevention />}></Route>
          <Route path={'/case-studies/aldi-vision-checkout'} element={<ALDIVisionCheckout />}></Route>
          <Route path={'/case-studies/cosfymart-app'} element={<Cosfymart />}></Route>
          <Route path={'/case-studies/cvs'} element={<CVS />}></Route>
          <Route path={'/case-studies/cv-shield'} element={<CVShield />}></Route>
          <Route path={'/case-studies/shopapp-app'} element={<ShopApp />}></Route>
          <Route path={'/case-studies/equinux'} element={<Equinox />}></Route>
          <Route path={'/case-studies/aladdin-app'} element={<Aladdin />}></Route>
          <Route path={'/case-studies/freshers-all-app'} element={<FreshersAll />}></Route>
          <Route path={'/case-studies/intel-vision-checkout'} element={<IntelVisionCheckout />}></Route>
          <Route path={'/case-studies/jedidahs'} element={<JedediahVisionCheckout />}></Route>
          <Route path={'/case-studies/leela-kitchen'} element={<LeelaKitchen />}></Route>
          <Route path={'/case-studies/nnb'} element={<NourishBloom />}></Route>
          <Route path={'/case-studies/pauls-fashion'} element={<PaulFashion />}></Route>
          <Route path={'/case-studies/product-box-app'} element={<ProductBox />}></Route>
          <Route path={'/case-studies/qlm-app'} element={<QLM />}></Route>
          <Route path={'/case-studies/smart-fridge'} element={<SmartFridge />}></Route>
          <Route path={'/case-studies/tap-and-go'} element={<TapAndGo />}></Route>
          <Route path={'/case-studies/treux-app'} element={<Truex />}></Route>
          <Route path={'/case-studies/canteen-app'} element={<Canteen />}></Route>
          <Route path={'/privacy-policy'} element={<PrivacyPolicy />}> </Route>
          <Route path={'/terms-of-service'} element={<TermsOfService />}></Route>
          <Route path={'/applying-for-role-java-developer'} element={<Job1 />}></Route>
          <Route path={'/applying-for-role-cv-engineer'} element={<Job2 />}></Route>
          <Route path={'/applying-for-role-devops-engineer'} element={<Job3 />}></Route>
          <Route path={'/applying-for-role-javascript-developer-experience'} element={<Job4 />}></Route>
          <Route path={'/applying-for-role-javascript-developer-fresher'} element={<Job5 />}></Route>
          <Route path={'/applying-for-role-mobile-developer-react-native-and-iOS-developer'} element={<Job6 />}></Route>
          <Route path={'/solutions'} element={<Solutions />}></Route >
          <Route path={'/solution-loss-prevention'} element={<LP_Solution />}></Route >
          <Route path={'/solutions/loss-prevention'} element={<Retail_Media />}></Route>
          <Route path={'/solution-unattended-retail'} element={<Unattended_Retail />}></Route>
          <Route path={'/solution-operational-excellence'} element={<Operational_Excellence />}></Route>
          <Route path={'/industry-retail'} element={<InRetail />}></Route>
          <Route path={'/research'} element={<Research />}></Route>
          <Route path={'/research-detail1'} element={<ResearchDetail1 />}></Route>
          <Route path={'/solutions-loss-prevention'} element={<Loss_Prevention_solution />}></Route>
          <Route path={'/solutions/pill-counting'} element={<Pill_Counting_Solution />}></Route>
          <Route path={'/solutions/vision-analytics'} element={<Vision_Analytics_Solution />}></Route>
          <Route path={'/solutions/asset-tracking'} element={<Asset_Tracking_Solution />}></Route>
          <Route path={'/solutions/safety-security'} element={<Safety_Security_Solution />}></Route>
          <Route path={'/industry/retail'} element={<Industry_Retail />}></Route>
          <Route path={'/industry/healthcare'} element={<Industry_Healthcare />}></Route>
          <Route path={'/industry/education'} element={<Industry_Education />}></Route>
          <Route path={'/industry/manufacturing'} element={<Industry_Manufacturing />}></Route>
          <Route path={'/Solution'} element={<SolutionLanding />}></Route>
          {/* <Route path={'/Feature-Facial-Recogbition'} element={<FacialRecognition/>}></Route> */}
          <Route path={'/solutions/ai-attendance'} element={<AI_Attecndance />}></Route>
          <Route path={'/solutions/ai-for-quality-control'} element={<QualityControl />}></Route>
          <Route path={'/solutions/vision-selfcheckout'} element={<Vision_SOC />}></Route>
          <Route path={'/solution-operational-excellence'} element={<Operational_Excellence />}></Route>
          <Route path={'/industry-retail'} element={<InRetail />}></Route>
          <Route path={'/research'} element={<Research />}></Route>
          <Route path={'/research-detail1'} element={<ResearchDetail1 />}></Route>
          <Route path={'/solutions-loss-prevention'} element={<Loss_Prevention_solution />}></Route>
          <Route path={'/solutions/pill-counting'} element={<Pill_Counting_Solution />}></Route>
          <Route path={'/solutions/vision-analytics'} element={<Vision_Analytics_Solution />}></Route>
          <Route path={'/solutions/asset-tracking'} element={<Asset_Tracking_Solution />}></Route>
          <Route path={'/solutions/safety-security'} element={<Safety_Security_Solution />}></Route>
          <Route path={'/industry/retail'} element={<Industry_Retail />}></Route>
          <Route path={'/industry/healthcare'} element={<Industry_Healthcare />}></Route>
          <Route path={'/industry/education'} element={<Industry_Education />}></Route>
          <Route path={'/industry/manufacturing'} element={<Industry_Manufacturing />}></Route>
          <Route path={'/Solution'} element={<SolutionLanding />}></Route>
          <Route path={'/features/facial-recognition'} element={<FacialRecognition />}></Route>
          <Route path={'/features/person-object-tracking'} element={<PersonObjectTracking />}></Route>
          <Route path={'/features/speed-calculation'} element={<SpeedCalculation />}></Route>
          <Route path={'/features/distance-calculation'} element={<DistanceCalculation />}></Route>
          <Route path={'/features/shape-size-estimation'} element={<ShapeSizeEstimation />}></Route>
          {/* <Route path={'/solution-Ai-attandance'} element={<AI_Attecndance />}></Route> */}
          <Route path={'/solutions/medical-image-analysis'} element={<Medical_Image_Analysis />}></Route>
          <Route path={'/research/ai-models-to-power-ai-applications'} element={<AIApplication />}></Route>
          <Route path={'/solutions/smart-fridge'} element={<Smart_Fridge_Solution />}></Route>
          <Route path={'/solutions/action-recognition'} element={<Action_Recognition_Solution />}></Route>
          <Route path={'/features/action-recognition'} element={<Action_Recognition />}></Route>
          <Route path={'/research/NVIDIA_DeepStream'} element={<DeepStream />}></Route>
          <Route path={'/research/intel_Deep_Learning_Streamer'} element={<IntelDLStreamer />}></Route>
          <Route path={'/research/Intel-tiber-edge-platform'} element={<EdgePlatform />}></Route>
          <Route path={'/testimonial'} element={<Testimonial />}></Route>
        </Route>
        <Route path={'*'} element={<Layout />}>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
