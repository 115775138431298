import React, { useState } from "react";
import { EnvelopeIcon, PhoneIcon, MapPinIcon, GlobeAmericasIcon, BriefcaseIcon, ChatBubbleOvalLeftEllipsisIcon, ClockIcon, StarIcon, } from "@heroicons/react/24/outline";
import { PATH } from "../../globals/app-constants";
import Btn from "../../component/Btn";
import FooterContact from "../../component/FooterContact";
import END_POINTS from '../../service/Endpoints'
var nospecial = /^[^*|\":<>[\]{}`\\()';@&$]+$/;

const Contact = () => {
    const [fields, setFields] = useState({})
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [contacts, setContacts] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        email: false,
        phone: false,
        message: false
    });
    const handleFieldChange = (field, value) => {
        setContacts((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const isValidEmail = (email) => {
        const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)
        return validEmailRegex.test(email);
    }

    const onlyNameRegex = new RegExp(`^[a-zA-Z ]+$`);
    const onlyCharactersRegex = new RegExp(`^[a-zA-Z0-9_ -()+]+$`);
    const onlyNumberRegex = new RegExp(`^[0-9.]+$`);
    const handleValidation = (currentField, value) => {
        switch (currentField) {
            case 'name':
                if (!value) {
                    return 'Please enter the valid name';
                } else if (!onlyNameRegex.test(value)) {
                    return 'Special characters and numbers are not allowed'
                }
                break;
            case 'email':
                if (!value) {
                    return 'Please enter the valid email address';
                } else if (!isValidEmail(value)) {
                    return 'Please enter the valid email address'
                }
                break;
            case 'phone':
                if (!value) {
                    return 'Please enter the valid phone number';
                } else if (!onlyNumberRegex.test(value)) {
                    return 'Numbers field only accepted.'
                } else if (value.length !== 10) {
                    return 'Please enter the valid phone number'
                }
                break;
            case 'message':
                if (!value) {
                    return 'Please enter the valid message';
                } else if (!onlyCharactersRegex.test(value)) {
                    return 'Special characters are not allowed'
                }
                break;
            default:
                return null
        }
        return '';
    }

    // useEffect(() => {
    //     console.log("chk")
    //     handleValidation()
    // }, [fields])


    const handleEmailSuccess = (data) => {
        setContacts({
            name: '',
            email: '',
            phone: '',
            message: ''
        })
        setEmailSuccess(data?.success)
    }

    const handleSubmitValidation = (e) => {
        e.preventDefault();
        // Validate all fields before submission
        setEmailError('')
        setEmailSuccess('')
        const newErrors = {};
        let hasErrors = false;
        for (const field in contacts) {
            if (handleValidation(field, contacts?.[field])) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }

        setFieldChangedStatus(newErrors);
        if (!hasErrors) {
            const data = { ...contacts };
            fetch(`${END_POINTS.API_BASE_URL}/proglint/mail/trigger`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic cHJvZ2xpbnQ6dG5pbGdvcnAzMjE=',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => handleEmailSuccess(data))
                .catch(() => setEmailError(true));
        }
    }
    const handleNumberInput = (event) => {
        var txt = nospecial.test(event.target.value);
        if (!txt) {
            if (event.target.value == "" && fields?.phone == "") {
                event.target.value = event.target.value;
            } else if (event.target.value == "" && fields?.phone !== "") {
                if (fields?.phone?.length > 1) {
                    event.target.value = fields?.phone ?? "";
                } else if (fields?.phone?.length == 1 && event.target.value !== "") {
                    event.target.value = fields?.phone;
                } else if (fields?.phone !== "") {
                    if (event.target.value !== "") {
                        event.target.value = fields?.phone;
                    } else {
                        event.target.value = "";
                    }
                } else {
                    event.target.value = "";
                }
            } else {
                event.target.value = fields?.phone;
            }
        }
        else {
            event.target.value = event.target.value;
        }
    }
    return (
        <div>
            <div className="contact_banner mt-28">
                <img src={PATH.img.whiteBanner1} className="w-full min-h-96 lg:min-h-20 absolute top-0 left-0" alt="banner" />
                <section className="banner_content container mx-auto block md:hidden mt-28 mb-10 relative z-10">
                    <h1 className="text-xl md:text-3xl text-center fB text-hColor mb-3">Get a Quote</h1>
                    <p className="text-base text-center md:text-base text-pColor mb-2 lg:max-w-xl fR">Please fill in the form and our representative will get back to you.</p>
                </section>
                <div className="contact_form table w-full relative z-10">
                    <div className="table-cell align-middle h-full lg:py-14">
                        <div className="container mx-auto">
                            <div className="flex gap-16 w-full">
                                <div className="w-full md:w-2/6 mb-8 md:mb-0 hidden md:block">
                                    <div>
                                        <h2 className="text-xl md:text-3xl fB text-hColor mb-3">Get a Quote</h2>
                                        <p className="text-base text-pColor mb-2 lg:max-w-xl fR">Please fill in the form and our representative will get back to you.</p>
                                    </div>
                                    <div className="border-b-2 border-secondary pt-3 md:pt-6 pb-2">
                                        <h2 className="uppercase text-hColor text-lg fSB">225+ PROJECTS DELIVERED</h2>
                                        <p className="text-sm text-pColor my-2 lg:max-w-xl fR">Extensive experience in building Web & Mobile development applications.</p>
                                    </div>
                                    <div className="border-b-2 border-primary pt-6 pb-2">
                                        <h2 className="uppercase text-hColor text-lg fSB">4+ AWARDS</h2>
                                        <p className="text-sm text-pColor my-2 lg:max-w-xl fR">Recognized with Awards & Accolades for highest quality and innovation</p>
                                    </div>
                                    <div className="border-b-2 border-[#26557D] pt-6 pb-2">
                                        <h2 className="uppercase text-hColor text-lg fSB">100+ EXPERTS</h2>
                                        <p className="text-sm text-pColor my-2 lg:max-w-xl fR">Team of skilled, professional and committed professionals.</p>
                                    </div>
                                    <div className="pt-6 pb-2">
                                        <h2 className="uppercase text-hColor text-lg fSB">50+ CLIENTS</h2>
                                        <p className="text-sm text-pColor my-2 lg:max-w-xl fR">Served clients across the globe from diverse domains.</p>
                                    </div>
                                </div>
                                <div className="w-full md:w-4/6">
                                    <div className="bg-white p-6 md:p-10 lg:p-14 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                                        <div className="mb-4">
                                            <label for="name" className="block mb-2 text-base fR text-pColor">Your Name</label>
                                            <input
                                                type="text"
                                                value={contacts?.name}
                                                id="name"
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                name="name"
                                                className="bg-white border-b-2 border-[#D2D2D2] text-base text-pColor outline-0 block w-full py-2.5 placeholder:text-sm placeholder:fSB placeholder-gray-500 placeholder-opacity-25"
                                                placeholder="Please enter your name" />
                                            {fieldChangedStatus?.name && handleValidation('name', contacts?.name) && (
                                                <p className="text-red-500 text-sm fR">{handleValidation('name', contacts?.name)}</p>)
                                            }
                                        </div>
                                        <div className="mb-4">
                                            <label for="email" className="block mb-2 text-base fR text-pColor">Email</label>
                                            <input
                                                type="email"
                                                value={contacts?.email}
                                                id="email"
                                                onChange={(e) => handleFieldChange('email', e.target.value)}
                                                name="email"
                                                className="bg-white border-b-2 border-[#D2D2D2] text-base text-pColor outline-0 block w-full py-2.5 placeholder:text-sm placeholder:fSB placeholder-gray-500 placeholder-opacity-25"
                                                placeholder="Please enter your email" />
                                            {fieldChangedStatus?.email && handleValidation('email', contacts?.email) && (
                                                <p className="text-red-500 text-sm fR">{handleValidation('email', contacts?.email)}</p>)
                                            }
                                        </div>
                                        <div className="mb-4">
                                            <label for="phone" className="block mb-2 text-base fR text-pColor">Phone</label>
                                            <input
                                                type="text"
                                                value={contacts?.phone}
                                                id="phone"
                                                onChange={(e) => handleFieldChange('phone', e.target.value)}
                                                onInput={(e) => handleNumberInput(e)}
                                                name="phone"
                                                maxLength={10}
                                                className="bg-white border-b-2 border-[#D2D2D2] text-base text-pColor outline-0 block w-full py-2.5 placeholder:text-sm placeholder:fSB placeholder-gray-500 placeholder-opacity-25"
                                                placeholder="Please enter your contact number" />
                                            {fieldChangedStatus?.phone && handleValidation('phone', contacts?.phone) && (
                                                <p className="text-red-500 text-sm">{handleValidation('phone', contacts?.phone)}</p>)
                                            }
                                        </div>
                                        <div className="mb-4">
                                            <label for="message" className="block mb-2 text-base fR text-pColor">Message</label>
                                            <textarea
                                                placeholder="Please enter your message here"
                                                id="message"
                                                value={contacts?.message}
                                                rows="4"
                                                name="message"
                                                onChange={(e) => handleFieldChange('message', e.target.value)}
                                                className="bg-white w-full border-b-2 border-[#D2D2D2] text-base outline-0 placeholder:text-sm placeholder:fSB placeholder-gray-500 placeholder-opacity-25"></textarea>
                                            {fieldChangedStatus?.message && handleValidation('message', contacts?.message) && (
                                                <p className="text-red-500 text-sm fR">{handleValidation('message', contacts?.message)}</p>)
                                            }
                                        </div>
                                        <div className="flex justify-center pt-8">
                                            <Btn value={'Send Message'} bgColor={'white'} onClick={(e) => handleSubmitValidation(e)} />
                                            {/* <Button value={'Send Message'} className={'px-20'} onClick={(e) => handleSubmitValidation(e)} /> */}
                                        </div>
                                        {emailSuccess && <div className='py-3 px-4 bg-[#00890026] rounded-md mt-8'>
                                            <p className='text-sm fR'>Thank you! We will get back to you shortly.</p>
                                        </div>}
                                        {
                                            emailError &&
                                            <div className='py-3 px-4 bg-[#fe8a8a] rounded-md mt-8'>
                                                <p className='text-sm fR'>Opps! Something went wrong.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto pt-10 pb-4 md:pb-8 relative z-10">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">We’re Here to Help</h2>
                    <p className="text-sm md:text-base text-center text-pColor mb-3 lg:px-44">We listen to your requirements analyze and suggest the best approach possible for your development.</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 my-10 md:my-14 gap-8 lg:gap-16">
                        <div className="flex flex-col">
                            <h2 className="text-xl fSB text-hColor pb-4">Share Your Requirement</h2>
                            <p className="border-t-2 border-primary text-pColor pt-2 text-base flex-grow">We keenly analyze your requirements from the beginning for a seamless development process.</p>
                        </div>
                        <div className="flex flex-col">
                            <h2 className="text-xl fSB text-hColor pb-4">Non Disclosure Agreement</h2>
                            <p className="border-t-2 border-[#26557D] text-pColor pt-2 text-base flex-grow">Your business ideas are always safe with us. We assure you complete confidentiality with NDA.</p>
                        </div>
                        <div className="flex flex-col">
                            <h2 className="text-xl fSB text-hColor pb-4">Under standing Your Requirement</h2>
                            <p className="border-t-2 border-secondary text-pColor pt-2 text-base flex-grow">Once we have your requirement, we will allocate our expert team for consultation to choose the right approach.</p>
                        </div>
                    </div>
                </div>
                <div className="relative z-10 contact_content_banner mb-8 before:absolute before:left-0 before:right-0 before:top-0  before:h-full before:w-full before:bg-gradient-to-r from-gray-900 to-[#F89B60] before:opacity-75">
                    <div className="z-30 border-b-2 pb-5 lg:pb-8 border-gray-200  container mx-auto flex md:flex-row flex-col md:justify-around md:items-center gap-5 md:gap-0">
                        <div className="flex gap-4 mb-2 md:mb-0">
                            <div className="relative m-0 lg:m-0 w-10 h-10 ring-2 ring-gray-200 lg:ring-0 lg:w-12 lg:h-12 overflow-hidden bg-[#EFEFEF] rounded-sm lg:rounded-lg ">
                                <EnvelopeIcon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 lg:p-1 w-10 h-10 text-black" />
                            </div>
                            <div className="flex flex-col flex-grow z-10">
                                <h4 className="text-lg text-white font-medium ">E-mail</h4>
                                <p className="text-sm text-white fR flex-grow">info@proglint.com</p>
                            </div>
                        </div>
                        <div className="flex gap-4 mb-2 md:mb-0">
                            <div className="relative m-0 lg:m-0 w-10 h-10 ring-2 ring-gray-200 lg:ring-0 lg:w-12 lg:h-12 overflow-hidden bg-[#EFEFEF] rounded-sm lg:rounded-lg ">
                                <PhoneIcon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 lg:p-1 w-10 h-10 text-black" />
                            </div>
                            <div className="flex flex-col flex-grow z-10">
                                <h4 className="text-lg text-white font-medium ">Phone</h4>
                                <p className="text-sm text-white fR flex-grow">+91-72000 12337</p>
                            </div>
                        </div>
                        <div className="flex gap-4 mb-2 md:mb-0">
                            <div className="relative m-0 lg:m-0 w-10 h-10 ring-2 ring-gray-200 lg:ring-0 lg:w-12 lg:h-12 overflow-hidden bg-[#EFEFEF] rounded-sm lg:rounded-lg ">
                                <MapPinIcon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 lg:p-1 w-10 h-10 text-black" />
                            </div>
                            <div className="flex flex-col flex-grow z-10">
                                <h4 className="text-lg text-white font-medium ">Address</h4>
                                <p className="text-sm text-white fR w-72 flex-grow">Prince Info City I, 3rd Floor, #286/1, Rajiv Gandhi Salai, Nehru Nagar, Perungudi, Chennai, Tamil Nadu 600096.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center container mx-auto">
                        <div className="grid grid-cols-2 lg:grid-cols-5 gap-5 lg:gap-20 mt-5 lg:mt-10 py-4 lg:py-8">
                            <div className="z-30 flex flex-col border border-gray-200 p-6 lg:border-none lg:p-0">
                                <GlobeAmericasIcon className="text-white w-14 h-14" />
                                <p className="text-lg text-white my-1 lg:w-36 flex-grow">Global Connectivity</p>
                            </div>
                            <div className="z-30 flex flex-col border border-gray-200 p-6 lg:border-none lg:p-0">
                                <ChatBubbleOvalLeftEllipsisIcon className="text-white w-14 h-14" />
                                <p className="text-lg text-white my-1 lg:w-36 flex-grow">Ignite Ideas, Spark Innovation</p>
                            </div>
                            <div className="z-30 flex flex-col border border-gray-200 p-6 lg:border-none lg:p-0">
                                <ClockIcon className="text-white w-14 h-14" />
                                <p className="text-lg text-white my-1 lg:w-36 flex-grow">Flexible Impact Hours</p>
                            </div>
                            <div className="z-30 flex flex-col border border-gray-200 p-6 lg:border-none lg:p-0">
                                <BriefcaseIcon className="text-white w-14 h-14" />
                                <p className="text-lg text-white my-1 lg:w-36 flex-grow">Work with Purpose</p>
                            </div>
                            <div className="z-30 flex flex-col border border-gray-200 p-6 lg:border-none lg:p-0">
                                <StarIcon className="text-white w-14 h-14" />
                                <p className="text-lg text-white my-1 lg:w-36 flex-grow">Acknowledged Tenacity</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default Contact;