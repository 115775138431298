import logoWhite from '../asset/images/white_logo.webp'
import logoBlack from '../asset/images/black_logo.webp'
import overlay from '../asset/images/overlay.webp'
import linkedin from '../asset/images/Linkedin.png'
import instagram from '../asset/images/Instagram.png'
import facebook from '../asset/images/Facebook.png'
import twitter from '../asset/images/Twitter.png'
import icon1 from '../asset/images/icon/icon1.svg'
import icon2 from '../asset/images/icon/icon2.svg'
import icon3 from '../asset/images/icon/icon3.svg'
import icon4 from '../asset/images/icon/icon4.svg'
import icon5 from '../asset/images/icon/icon5.svg'
import icon6 from '../asset/images/icon/icon6.svg'
import icon7 from '../asset/images/icon/icon7.svg'
import walkin from '../asset/images/Walk In Walk Out.jpg'
import pauls from '../asset/images/the_pauls_faction1.jpg'
import overlayLogo from '../asset/images/overlay_logo.svg'
import web from '../asset/images/icon/web.svg'
import mobile from '../asset/images/icon/mobile.svg'
import product from '../asset/images/icon/product.svg'
import vision from '../asset/images/icon/vision.svg'
import careerimg from '../asset/images/career_img.webp'
import contactimg from '../asset/images/contact_img.webp'
import globe from '../asset/images/globe.webp'
import USA from '../asset/images/usa.png'
import Europe from '../asset/images/Europe_flag.jpg'
import thailand from '../asset/images/thailand.png'
import bulb from '../asset/images/icon/bulb.svg'
import scale from '../asset/images/icon/scale.svg'
import streamline from '../asset/images/icon/streamline.svg'
import proglintCartoonImg from '../asset/images/proglint_cartoon_img.webp'
import promisesIcon1 from '../asset/images/icon/promises_icon1.svg'
import promisesIcon2 from '../asset/images/icon/promises_icon2.svg'
import promisesIcon3 from '../asset/images/icon/promises_icon3.svg'
import companyBanner from '../asset/images/company_banner.webp'
import b1 from '../asset/images/b1.png'
import b2 from '../asset/images/b2.png'
import b3 from '../asset/images/b3.png'
import b4 from '../asset/images/b4.png'
import b5 from '../asset/images/b5.png'
import b6 from '../asset/images/b6.png'
import activity1 from '../asset/images/activity1.webp'
import activity2 from '../asset/images/activity2.webp'
import activity3 from '../asset/images/activity3.webp'
import activity4 from '../asset/images/activity4.webp'
import activity5 from '../asset/images/activity5.webp'
import activity6 from '../asset/images/activity6.webp'
import activity7 from '../asset/images/activity7.webp'
import activity8 from '../asset/images/activity8.webp'
import activity9 from '../asset/images/activity9.webp'
import appsBanner from '../asset/images/apps_banner.webp'
import appsdevice from '../asset/images/apps_device_img.webp'
import aiml from '../asset/images/icon/ai_ml.svg'
import Iretail from '../asset/images/retail_img.webp'
import Ihealth from '../asset/images/health_img.webp'
import Ifinancial from '../asset/images/financial_img.webp'
import Ieduction from '../asset/images/education_img.webp'
import IretailIcon from '../asset/images/icon/Iretail_icon.svg'
import IeducationIcon from '../asset/images/icon/Ieducation_icon.svg'
import IhealthIcon from '../asset/images/icon/Ihealth_icon.svg'
import IfinancialIcon from '../asset/images/icon/Ifinancial_icon.svg'
import performance from '../asset/images/icon/performance.svg'
import puzzle from '../asset/images/icon/puzzle.svg'
import onTime from '../asset/images/icon/on_time.svg'
import quality from '../asset/images/icon/quality.svg'
import contentBanner1 from '../asset/images/content_banner1.webp'
import agile from '../asset/images/Agile_flow.png'
import hello from '../asset/images/icon/hello.svg'
import webBanner from '../asset/images/web_banner.png'
import appdev from '../asset/images/icon/app_dev.svg'
import ampdev from '../asset/images/icon/amp_dev.svg'
import development from '../asset/images/icon/development.svg'
import html from '../asset/images/icon/html.svg'
import uiux from '../asset/images/icon/ui_ux.svg'
import webComputer from '../asset/images/icon/web_computer.svg'
import design from '../asset/images/icon/design.svg'
import growth from '../asset/images/icon/growth.svg'
import BusinessService from '../asset/images/icon/business_sevice.svg'
import User from '../asset/images/icon/user.svg'
import contentBanner2 from '../asset/images/content_banner2.webp'
import mobileBanner from '../asset/images/mobile_banner.png'
import mobileDevice1 from '../asset/images/mobile_device1.png'
import mobileDevice2 from '../asset/images/mobile_device2.png'
import mobileDevice3 from '../asset/images/mobile_device3.png'
import contentBanner3 from '../asset/images/content_banner3.webp'
import mobileApp from '../asset/images/icon/mobile_app.svg'
import appSecurity from '../asset/images/icon/app_security.svg'
import appQuality from '../asset/images/icon/app_quality.svg'
import nativeApp from '../asset/images/icon/native_app.svg'
import crossApp from '../asset/images/icon/cross_app.svg'
import appSupport from '../asset/images/icon/support_app.svg'
import AimlBanner from '../asset/images/Ai_Ml_banner.png'
import AiMlIcon from '../asset/images/icon/ai_ml.svg'
import deepLearning from '../asset/images/icon/deep_learning.svg'
import cvVision from '../asset/images/icon/cv_vision.svg'
import Annotation from '../asset/images/icon/annotation.svg'
import machineLearning from '../asset/images/icon/machine_learning.svg'
import Bot from '../asset/images/icon/bot.svg'
import voiceAi from '../asset/images/icon/voice_ai.svg'
import imageAnalytics from '../asset/images/icon/img_analytics.svg'
import dataForcsting from '../asset/images/icon/data_forecasting.svg'
import engine from '../asset/images/icon/engine_icon.svg'
import dataAnalytics from '../asset/images/icon/data_annlytics.svg'
import predictiveAnalytics from '../asset/images/icon/predictive_analytics.svg'
import productBanner from '../asset/images/product_banner.png'
import devMethodology from '../asset/images/dev_methodology.webp'
import memory from '../asset/images/icon/memory.svg'
import planning from '../asset/images/icon/planning.svg'
import approach from '../asset/images/icon/approach.svg'
import feasibility from '../asset/images/icon/feasibility.svg'
import feedback from '../asset/images/icon/feedback.svg'
import bug from '../asset/images/icon/bug.svg'
import webLaptop from '../asset/images/icon/web_laptop.svg'
import security from '../asset/images/icon/security.svg'
import agileIcon from '../asset/images/icon/agile.svg'
import whiteBanner from '../asset/images/white_banner.png'
import iosDevice from '../asset/images/ios_device.webp'
import androidDevice from '../asset/images/android_device.webp'
import deploy from '../asset/images/icon/deploy.svg'
import appleLogo from '../asset/images/icon/apple_logo.svg'
import treuxCoverimg from '../asset/images/treux_cove_img.png'
import cosfyCoverimg from '../asset/images/cosfy_cover_img.png'
import cvsCoverimg from '../asset/images/cvs_cover_img.png'
import humanCoverimg1 from '../asset/images/human_cover_img1.png'
import androidLogo from '../asset/images/icon/android_logo.svg'
import settingIdea from '../asset/images/icon/settings_idea.svg'
import delivering from '../asset/images/icon/delivering.svg'
import endUser from '../asset/images/icon/end_user.svg'
import designTool from '../asset/images/icon/design_tool.svg'
import hybridDesvice from '../asset/images/hybrid_device.webp'
import hybridLogo from '../asset/images/icon/hybrid_logo.svg'
import easyIntegration from '../asset/images/icon/easyIntegration.svg'
import meterialDesign from '../asset/images/icon/materialDesign.svg'
import mobileNativeapps from '../asset/images/icon/mobilenative_apps.svg'
import shortTdev from '../asset/images/icon/shortTdev.svg'
import thirdParty from '../asset/images/icon/thirdParty.svg'
import dartBoard from '../asset/images/icon/dartBoard.svg'
import trendIcon from '../asset/images/icon/trend_icon.svg'
import reactNativeLogo from '../asset/images/icon/reactNative_logo.svg'
import reactNativeDevice from '../asset/images/react_nativeDevice.jpg'
import productList from '../asset/images/icon/product_list.svg'
import bestReactNative from '../asset/images/icon/best_reactNative.svg'
import shareReactNative from '../asset/images/icon/share_native.svg'
import costEstimate from '../asset/images/icon/cost_setting.svg'
import MEANicon1 from '../asset/images/icon/MEAN_icon1.svg'
import MEANicon2 from '../asset/images/icon/MEAN_icon2.svg'
import MEANicon3 from '../asset/images/icon/MEAN_icon3.svg'
import MEANicon4 from '../asset/images/icon/MEAN_icon4.svg'
import MEANlogo from '../asset/images/icon/logoMEAN.svg'
import MERNlogo from '../asset/images/icon/MERN_logo.png'
import webAndapp from '../asset/images/icon/web&app.svg'
import lessTime from '../asset/images/icon/lessTime.svg'
import notification from '../asset/images/icon/notification.svg'
import alert from '../asset/images/icon/warning.svg'
import desktopDesign from '../asset/images/icon/desktop_design.svg'
import springBoot_logo from '../asset/images/icon/springBoot_logo.svg'
import API from '../asset/images/icon/api.svg'
import legacy_icon from '../asset/images/icon/legacy_application.svg'
import maintenance from '../asset/images/icon/maintenance.svg'
import securityLaptop from '../asset/images/icon/security_laptop.svg'
import javaLogo from '../asset/images/icon/java_logo.svg'
import pythoLogo from '../asset/images/icon/python_logo.png'
import pythonAPI from '../asset/images/icon/python_api.svg'
import pythonComputer from '../asset/images/icon/python_computer.svg'
import pythonDev from '../asset/images/icon/python_dev.svg'
import integrity from '../asset/images/icon/integrity.svg'
import zeroCost from '../asset/images/icon/zero_cost.svg'
import magnifying from '../asset/images/icon/magnifying.svg'
import systemSettings from '../asset/images/icon/system_settings.svg'
import smartThinking from '../asset/images/icon/smart_thinking.svg'
import rocket_icon from '../asset/images/icon/rocket_icon.svg'
// import staffAugmentation_logo from '../asset/images/icon/staff_augmentation_logo.svg'
import staffAugmentation_logo from '../asset/images/icon/staff_augmentation_logo.webp'
// import StaffAugmentationProcess from '../asset/images/icon/staff_augmentation_process.svg'
import StaffAugmentationProcess from '../asset/images/icon/staff_augmentation_process.jpg'
import predictableCost from '../asset/images/icon/predictable_cost.svg'
import scalling from '../asset/images/icon/scalling.svg'
import efficiency from '../asset/images/icon/efficiency.svg'
import communicationSkils from '../asset/images/icon/communication_skills.svg'
// import careerLogo from '../asset/images/career_logo.svg'
import careerLogo from '../asset/images/career_logo.webp'
import whiteBanner1 from '../asset/images/white_banner1.webp'
import productBanner1 from '../asset/images/product_banner1.webp'
import hiringProcess from '../asset/images/hiring_process.jpg'
import height1 from '../asset/images/height1.webp'
import height2 from '../asset/images/height2.webp'
import height3 from '../asset/images/height3.webp'
import height4 from '../asset/images/height4.webp'
import height5 from '../asset/images/height5.webp'
import height6 from '../asset/images/height6.webp'
import home_icon from '../asset/images/icon/home_icon.svg'
import tabHomeicon from '../asset/images/icon/tab_home_icon.svg'
import tabAppsicon from '../asset/images/icon/tab_apps_icon.svg'
import tabProducticon from '../asset/images/icon/tab_produt_icon.svg'
import tabBlogicon from '../asset/images/icon/tab_blog_icon.svg'
import companyIcon from '../asset/images/icon/company_icon.svg'
import tabCompanyicon from '../asset/images/icon/tab_company_icon.svg'
import blogIcon from '../asset/images/icon/blog_icon.svg'
import appsIcon from '../asset/images/icon/apps_icon.svg'
import contentBanner4 from '../asset/images/content_banner4.webp'
import portfolioBanner from '../asset/images/portfolio_banner.webp'
import domainIcon from '../asset/images/icon/domain.svg'
import duraionIcon from '../asset/images/icon/duration.svg'
import technologyIcon from '../asset/images/icon/technology.svg'
import single_page_application from '../asset/images/single_page_application.png'
import android_app_challenges from '../asset/images/android_app_challenges.png'
import blogPoster from '../asset/images/blog_poster.png'
import productIcon from '../asset/images/icon/productIcon.svg'
import quoteIcon from '../asset/images/icon/quote.svg'
import costSaving from '../asset/images/icon/cost_saving.svg'
import VisioncomputingBanner from '../asset/images/vision_computing_banner.webp'
import ObjectDection from '../asset/images/object_detection.webp'
import ImageClassification from '../asset/images/image_classification.webp'
import poseEstimation from '../asset/images/pose_estimation.webp'
import collectIcon from '../asset/images/icon/collect.svg'
import annotateIcon from '../asset/images/icon/annotateIcon.svg'
import trainingIcon from '../asset/images/icon/trainingIcon.svg'
import connectIcon from '../asset/images/icon/connect.svg'
import monitorIcon from '../asset/images/icon/monitorIcon.svg'
import maintainIcon from '../asset/images/icon/maintainIcon.svg'
import deployIcon from '../asset/images/icon/deployeIcon.svg'
import collectData from '../asset/images/collect_data.png'
import ImagesegmentationIcon from '../asset/images/image_segmentation-icon.webp'
import objectDectionIcon from '../asset/images/object_detection-icon.webp'
import poseEstimationIcon from '../asset/images/pose_estimation-icon.png'
// import portfolioLossprevention1 from '../asset/images/portfolio-img/lossprevention1.png'
import freshersAllThumbnail from '../asset/images/portfolio-img/freshers-all.webp'
import shopAppThumbnail from '../asset/images/portfolio-img/shopapp.png'
import cosfymartThumbnail from '../asset/images/portfolio-img/cosfymart-Thumbnail.webp'
import treuxThumbnail from '../asset/images/portfolio-img/treux-thumbnail.webp'
// import AVMThumbnail from '../asset/images/portfolio-img/AVM-thumbnail.png'
// import canteenThumbnail from '../asset/images/portfolio-img/canteen-thumbnail.png'
import intelAngular from '../asset/images/intel-angular.webp'
import paulsReact from '../asset/images/pauls-react.webp'
import collectVC from '../asset/images/collect-vc.webp'
import annotateVC from '../asset/images/annotate-vc.webp'
import trinVC from '../asset/images/training-vc.webp'
import developVC from '../asset/images/develop-vc.webp'
import deployVC from '../asset/images/deploy-vc.webp'
import connectVC from '../asset/images/connect-vc.webp'
import monitorVC from '../asset/images/monitor-vc.webp'
import maintainVC from '../asset/images/maintain-vc.webp'
import secureVC from '../asset/images/secure-vc.webp'
import CVShieldThumbnail from '../asset/images/portfolio-img/CV-Shield.webp'
// import CVShieldThumbnail from '../asset/images/portfolio-img/CV-Shield.jpg'
import CVSThumbnail from '../asset/images/portfolio-img/CVS.webp'
import EquionxThumbnail from '../asset/images/portfolio-img/Equinox.webp'
import FreshersAllThumbnail from '../asset/images/portfolio-img/freshers-all.webp'
import IntelThumbnail from '../asset/images/portfolio-img/Intel.png'
import JedediahsThumbnail from '../asset/images/portfolio-img/jedediahs.webp'
import LeelaKitchenThumbnail from '../asset/images/portfolio-img/LeelaKitchen.webp'
import NNBThumbnail from '../asset/images/portfolio-img/NNB.webp'
import PaulFashionThumbnail from '../asset/images/portfolio-img/Pauls-fashion.webp'
import ProductBoxThumbnail from '../asset/images/portfolio-img/product-Box.webp'
import QLMThumbnail from '../asset/images/portfolio-img/QLM.webp'
import SmartFridgeThumbnail from '../asset/images/portfolio-img/Smart-Fridge.webp'
import TapandGoThumbanail from '../asset/images/portfolio-img/Tap&Go.png'
import CanteenThumbnail from '../asset/images/portfolio-img/canteen_thumbnail.png'
import losspreventionThumbnail from '../asset/images/portfolio-img/Loss-prevention.png'
import aladdinThumbnail from '../asset/images/portfolio-img/aladdin.webp'
import FreshersAllBanner from '../asset/images/Portfolio-banner/freshers-all-banner.png'
import CanteenBanner from '../asset/images/Portfolio-banner/canteen_banner.png'
import cosfymartBanner from '../asset/images/Portfolio-banner/cosfymart-banner.png'
import CVShieldBanner from '../asset/images/Portfolio-banner/CV-shield-banner.png'
import CVSBanner from '../asset/images/Portfolio-banner/CVS-banner.png'
import equinoxBanner from '../asset/images/Portfolio-banner/equinox-banner.png'
import freshersallBanner from '../asset/images/Portfolio-banner/freshers-all-banner.png'
import intelBanner from '../asset/images/Portfolio-banner/Intal-banner.png'
import JedediahBanner from '../asset/images/Portfolio-banner/jedediahs-banner.png'
import leelakitchenBanner from '../asset/images/Portfolio-banner/leelakitchen-banner.png'
import losspreventionBanner from '../asset/images/Portfolio-banner/loss-prevention-banner.webp'
import NNBBanner from '../asset/images/Portfolio-banner/NNB-banner.png'
import PaulsfashionBanner from '../asset/images/Portfolio-banner/pauls-fashion-banner.png'
import porductBoxBanner from '../asset/images/Portfolio-banner/product-box-banner.png'
import QLMBanner from '../asset/images/Portfolio-banner/QLM-banner.png'
import ShopAppBanner from '../asset/images/Portfolio-banner/shop-app-banner.png'
import smartfridgeBanner from '../asset/images/Portfolio-banner/smart-fridge-banner.png'
import TreuxBanner from '../asset/images/Portfolio-banner/Treux-banner.png'
import clientSaysThumbnail from '../asset/images/vending-machine.webp'
import treuxFlutter from '../asset/images/treux-flutter.webp'
import CVshieldWeb from '../asset/images/cv-shield-web.webp'
// import lossPreventionWeb from '../asset/images/loss-preventation-web.png'
import lossPreventionWeb from '../asset/images/loss-preventation-web.webp'
import shopAppWeb from '../asset/images/shopapp-web.png'
import Canteenweb from '../asset/images/canteen-web.webp'
import NNBweb from '../asset/images/nnb-web.png'
import aladdinWeb from '../asset/images/aladdin-web.png'
import blogImg from '../asset/images/blog-1.webp'
import blogImg2 from '../asset/images/blog-2.webp'
import blogDetailImg from '../asset/images/blog-detail-1.jpg'
import blogDetailImg2 from '../asset/images/blogdetailImg2.jpg'
import blogDetailImg3 from '../asset/images/blogDetailImg3.jpg'
import blogDetailImg4 from '../asset/images/blogDetailImg4.jpg'
import blogDetailImg5 from '../asset/images/blogDetailImg5.jpg'
import blogDetailImg6 from '../asset/images/blogDetailImg6.jpg'
import blogDetailImg7 from '../asset/images/blogDetailImg7.png'
import blogDetailImg8 from '../asset/images/blogDetailImg8.jpg'
import blogDetailImg9 from '../asset/images/blogDetailImg9.jpg'
import blogDetailImg10 from '../asset/images/blogDetailImg10.jpg'
import blogDetailImg11 from '../asset/images/blogDetailImg11.jpg'
import blogDetailImg12 from '../asset/images/blogDetailImg12.jpg'
import blogDetailImg13 from '../asset/images/blogDetailImg13.jpg'
import Tap_and_go_banner from '../asset/images/Portfolio-banner/Tap_and_go.png'
import Tap_and_go_thumbnail from '../asset/images/portfolio-img/tap_and_go_detail.png'
import error_404 from '../asset/images/404 page .png'
import AladdinDetail from '../asset/images/Portfolio-banner/aladdin_banner.png'
import LP_solution from '../asset/images/lp_solution.png'
import RM_solution from '../asset/images/rm_solution.png'
import UR_solution from '../asset/images/ur_solution.png'
import OE_solution from '../asset/images/oe_solution.png'
import Enquiry_icon from '../asset/images/enquiry-icon.png'
import LP_solution_detail from '../asset/images/lp_solution_detail_banner.png'
import LP_Solution_icon1 from '../asset/images/lp_solution_icon1.svg'
import LP_Solution_icon2 from '../asset/images/lp_solution_icon2.svg'
import LP_Solution_icon3 from '../asset/images/lp_solution_icon3.svg'
import LP_Solution_detail_line_icon1 from '../asset/images/lp_solution_detail_line_icon1.svg'
import LP_Solution_detail_line_icon2 from '../asset/images/lp_solution_detail_line_icon2.svg'
import LP_Solution_detail_line_icon3 from '../asset/images/lp_solution_detail_line_icon3.svg'
import LP_Solution_detail_line_icon4 from '../asset/images/lp_solution_detail_line_icon4.svg'
import LP_solution_map from '../asset/images/lp-map.png'
import Solution_Retail_Media from '../asset/images/solution_retail_media.png'
import Solution_Operational from '../asset/images/solution_operational.png'
import Solution_Unattended_Retail from '../asset/images/solution_unattended_retail.png'
import AiattendanceImg from '../asset/images/aiAttendance.webp'
import CompanyAboutImg from '../asset/images/company-about.jpg'
import about from '../asset/images/about.webp'
import Bgcircle from '../asset/images/circle-bg.webp'
import RetailBenifitsImg from '../asset/images/retail-benefits-img.png'
import RetailKeyfeaturesImg from '../asset/images/retail-key-features-img.png'
import IndustryIcon from '../asset/images/industy-icon.png'
import TechnologyIcon from '../asset/images/technology-icon.png'
import EffortIcon from '../asset/images/effort-icon.png'
import DomainbannerIcon from '../asset/images/domain-icon.png'
import DeliveryModalIcon from '../asset/images/delivery-modal-icon.png'
import FdIcon from '../asset/images/fb.png'
import InstaIcon from '../asset/images/insta.png'
import LinkedInIcon from '../asset/images/Linked-In.png'
import TwitterIcon from '../asset/images/Twitter.png'
import EducationIcon from '../asset/images/icon/education-icon.png'
import ManufacturingIcon from '../asset/images/icon/Manufacturing-icon.png'
import HealthcareIcon from '../asset/images/icon/health-icon.png'
import RetailIcon from '../asset/images/icon/retail-icon.png'
import DriveIcon from '../asset/images/icon/drive-icon.png'
import ResearchBook from '../asset/images/research-book.png'
import InstagramIcon from '../asset/images/icon/instagram-icon.png'
import LinkedinIcon from '../asset/images/icon/linkedin-icon.png'
import Featuresfacialrecognition from '../asset/images/Features-img/facial-recognition.jpg'
import FeaturesPersontracking from '../asset/images/Features-img/Person-Object-Tracking.jpg'
import FeaturesSpeedcalculation from '../asset/images/Features-img/speed-calculation.jpg'
import FeaturesShapeEstimation from '../asset/images/Features-img/Shape-and-Size-estimation.jpg'
import FeaturesDistanceCalculation from '../asset/images/Features-img/distance-calculation.jpg'
import IndustryPillcount from '../asset/images/Industry-img/pill-count.jpg'
import SolutionLossprevention from '../asset/images/solution-img/Loss-prevention.jpg'
import SolutionLoss1 from '../asset/images/solution-img/solution-loss1.png'
import SolutionLoss2 from '../asset/images/solution-img/solutionloss2.png'
import SolutionAssettracking from '../asset/images/solution-img/Asset-tracking.jpg'
import SolutionPillcount from '../asset/images/solution-img/Pill-count.jpg'
import Solutionpill1 from '../asset/images/solution-img/solutionpill1.png'
import Solutionpill2 from '../asset/images/solution-img/solutionpill2.png'
import SolutionAsset1 from '../asset/images/solution-img/solutionasset1.png'
import SolutionAsset2 from '../asset/images/solution-img/solutionasset2.png'
import SolutionSafetyandsecurity from '../asset/images/solution-img/Safety-and-Security.jpg'
import SolutionSecurity1 from '../asset/images/solution-img/solutionsecurity1.png'
import SolutionSecurity2 from '../asset/images/solution-img/SolutionSecurity2.png'
import SolutionVisionAnalytics from '../asset/images/solution-img/vision-analytics.jpg'
import SolutionVision1 from '../asset/images/solution-img/solutionvision1.png'
import SolutionVision2 from '../asset/images/solution-img/solutionvision2.png'
import medicalanalysis from '../asset/images/icon/medical_analysis.svg'
import healthcare from '../asset/images/icon/healthcare.svg'
import aiicon from '../asset/images/icon/ai_icon.svg'
import securitymeasureicon from '../asset/images/icon/security_measure.svg'
import trackingicon from '../asset/images/icon/tracking.svg'
import facerecognition from '../asset/images/icon/face-recognition.svg'
import identityverficationicon from '../asset/images/icon/identity_verification.svg'
import materialdemandicon from '../asset/images/icon/raw_demand.svg'
import dashboardicon from '../asset/images/icon/dashboard.svg'
import safetyverificationicon from '../asset/images/icon/safety_verification.svg'
import ai from '../asset/images/icon/ai-icon.svg'
import SolutionLandingBanner from '../asset/images/solution-landingbanner.jpg'
import RetailtabImg from '../asset/images/Retail-tab-img.jpg'
import ManufacturingtabImg from '../asset/images/Manufacturing-tab-img.jpg'
import EducationtabImg from '../asset/images/Education-tab-img.jpg'
import HealthcaretabImg from '../asset/images/Healthcare-tab-img.jpg'
import pillicon from '../asset/images/icon/pill-icon.svg'
import losspreventionicon from '../asset/images/icon/loss-prevention-icon.svg'
import CircleObjecttracking from '../asset/images/Person-Object-Tracking-circle-banner.webp'
import CircleLossprevention from '../asset/images/LossCircle-banner.webp'
import AiAttendance1 from '../asset/images/solution-img/Ai-attendance1.png'
import AiAttendance2 from '../asset/images/solution-img/Ai-attendance2.png'
import SolutionQualityControl from '../asset/images/solution-img/Quality-Control.jpg'
import SolutionProductCounting from '../asset/images/solution-img/Product-Counting.jpg'
import SolutionWaitingtime from '../asset/images/solution-img/Optimized-Waiting-Times.jpg'
import SolutionImageAalyis from '../asset/images/solution-img/Medical-Image-Analysis.jpg'
import SolutionQualitycontrol1 from '../asset/images/solution-img/quality-control1.png'
import SolutionQualitycontrol2 from '../asset/images/solution-img/quality-control2.png'
import SolutionvisionSOC1 from '../asset/images/solution-img/vision-soc1.png'
import SolutionvisionSOC2 from '../asset/images/solution-img/vision-soc2.png'
import SolutionvisionSOCbanner from '../asset/images/solution-img/vision-soc.jpg'
import SolutionImageanalysis1 from '../asset/images/solution-img/image-analysis1.png'
import SolutionImageanalysis2 from '../asset/images/solution-img/image-analysis2.png'
import playplugicon from '../asset/images/icon/plug-play.svg'
import medicineicon from '../asset/images/icon/medicine.svg'
import qualitycontrolicon from '../asset/images/icon/quality-control.svg'
import stockicon from '../asset/images/icon/stock.svg'
import parkingicon from '../asset/images/icon/parking.svg'
import vehiclealerticon from '../asset/images/icon/vehicle_alert.svg'
import retailsecurityicon from '../asset/images/icon/retail-security.svg'
import solution_healthcare from '../asset/images/solution-img/loss_prevention_in_healthcare.jpg'
import solution_healthcare_img1 from '../asset/images/solution-img/solution-healthcare-img1.png'
import solution_healthcare_img2 from '../asset/images/solution-img/solution-healthcare-img2.png'
import research_detail_img1 from '../asset/images/research-img/research-detail-img1.png'
import research_detail_img2 from '../asset/images/research-img/research-detail-img2.png'
import research_detail_img3 from '../asset/images/research-img/research-detail-img3.png'
import thumbnail_face_recognition from '../asset/images/thumbnail-img/thumbnail-face-recognition.png'
import thumbnail_loss_prevention from '../asset/images/thumbnail-img/thumbnail-loss-prevention.webp'
import thumbnail_shape_size from '../asset/images/thumbnail-img/thumbnail-shape-size.png'
import thumbnail_vision_analytics from '../asset/images/thumbnail-img/thumbnail-vision-analytics.png'
import thumbnail_person_object_tracking from '../asset/images/thumbnail-img/thumbnail-person-object-tracking.png'
import visionanalyticsicon from '../asset/images/icon/vision-analytics-icon.svg'
import selfcheckouticon from '../asset/images/icon/self-checkout-icon.svg'
import safetysecurityicon from '../asset/images/icon/safety_security_icon.svg'
import assettrackingicon from '../asset/images/icon/asset_tracking_icon.svg'
import medicalimageicon from '../asset/images/icon/medical_image_icon.svg'
import smartfridgeimg1 from '../asset/images/solution-img/solutions-smart-fridge.jpg'
import smartfridgeimg2 from '../asset/images/solution-img/solution-smart-fridge-img2.png'
import smartfridgeimg3 from '../asset/images/solution-img/solution-smart-fridge-img3.png'
import industry_loss_prevention from '../asset/images/home_industry_img/loss-prevention.jpg'
import industry_vision_analytics from '../asset/images/home_industry_img/vision-analytics.jpg'
import industry_asset_tracking from '../asset/images/home_industry_img/asset-tracking.jpg'
import industry_ai_attendance from '../asset/images/home_industry_img/ai-attendance.jpg'
import industry_safety_security from '../asset/images/home_industry_img/safety-security.jpg'
import industry_quality_control from '../asset/images/home_industry_img/quality-control.jpg'
import industry_object_product from '../asset/images/home_industry_img/object-product.jpg'
import industry_people_counting from '../asset/images/home_industry_img/people-counting.jpg'
import industry_medical_image_analysis from '../asset/images/home_industry_img/medical-image-analysis.jpg'
import industry_pill_count from '../asset/images/home_industry_img/pill-count.jpg'
import industry_action_recognition from '../asset/images/home_industry_img/action-recognition.jpg'
import FeaturesActionRecognition from '../asset/images/Features-img/action-recognition.jpg'
import solution_action_recognition_img1 from '../asset/images/solution-img/action-recognition-img1.jpg'
import solution_action_recognition_img2 from '../asset/images/solution-img/action-recognition-img2.jpg'
import solution_action_recognition_img3 from '../asset/images/solution-img/action-recognition-img3.jpg'
import vision_analystics_img from '../asset/images/vision_analystics_img.webp'
import NVIDIADeepStream_img1 from '../asset/images/research-img/NVIDIA-DeepStream.png'
import IntelDeepLearningStreamer_img1 from '../asset/images/research-img/Intel-Deep-Learning-Streamer_img1.png'
import EdgePlatformImg1 from '../asset/images/research-img/Edge-Platform-img1.png'
import TestimonailImg1 from '../asset/images/testimonial-img/testimonial_img1.jpg'

export const PATH = {
    img: {
        logoWhite, logoBlack, overlay, linkedin, instagram, facebook, twitter, walkin, pauls, overlayLogo, careerimg, contactimg, globe, USA,
        Europe, thailand, proglintCartoonImg, companyBanner, b1, b2, b3, b4, b5, b6, activity1, activity2, activity3, activity4, activity5, activity6, activity7, activity8, activity9, SolutionLandingBanner,
        appsBanner, appsdevice, Ieduction, Ifinancial, Ihealth, Iretail, contentBanner1, agile, webBanner, contentBanner2, mobileBanner, mobileDevice1, mobileDevice2, mobileDevice3, contentBanner3, AimlBanner, productBanner, devMethodology, androidDevice, iosDevice, whiteBanner, treuxCoverimg, cosfyCoverimg, cvsCoverimg, humanCoverimg1, hybridDesvice, reactNativeDevice, careerLogo, whiteBanner1, productBanner1, hiringProcess, height1, height2, height3, height4, height5, height6, contentBanner4,
        portfolioBanner, android_app_challenges, single_page_application, blogPoster, VisioncomputingBanner, ObjectDection, poseEstimation, ImageClassification, collectData, poseEstimationIcon, objectDectionIcon, ImagesegmentationIcon, freshersAllThumbnail, shopAppThumbnail, cosfymartThumbnail, treuxThumbnail, paulsReact, intelAngular, collectVC, annotateVC, trinVC, developVC, deployVC, connectVC, monitorVC, maintainVC, secureVC, CVShieldThumbnail, CVSThumbnail, EquionxThumbnail, FreshersAllThumbnail, IntelThumbnail, JedediahsThumbnail, LeelaKitchenThumbnail, NNBThumbnail, PaulFashionThumbnail, ProductBoxThumbnail, QLMThumbnail, SmartFridgeThumbnail, TapandGoThumbanail, portfolioBanner, FreshersAllBanner, TreuxBanner, smartfridgeBanner, shopAppThumbnail, QLMBanner, porductBoxBanner, PaulsfashionBanner, NNBBanner, losspreventionBanner, leelakitchenBanner, JedediahBanner, intelBanner, freshersallBanner, equinoxBanner, CVSBanner, CVShieldBanner, CanteenBanner, ShopAppBanner, cosfymartBanner, clientSaysThumbnail, losspreventionThumbnail, aladdinThumbnail, treuxFlutter,
        CVshieldWeb, CanteenThumbnail, lossPreventionWeb, Canteenweb, AladdinDetail, shopAppWeb, Tap_and_go_banner, Tap_and_go_thumbnail, blogImg, blogImg2, blogDetailImg, blogDetailImg2, blogDetailImg3, blogDetailImg4, blogDetailImg5, blogDetailImg6, blogDetailImg7, blogDetailImg8, blogDetailImg9, blogDetailImg10, blogDetailImg11, blogDetailImg12, blogDetailImg13, NNBweb, aladdinWeb, error_404, AiattendanceImg, CompanyAboutImg, RetailKeyfeaturesImg, RetailBenifitsImg,
        LP_solution, RM_solution, UR_solution, OE_solution, Enquiry_icon, LP_solution_detail, LP_solution_map, Solution_Retail_Media, Solution_Operational, Solution_Unattended_Retail, about, Bgcircle, ResearchBook, FeaturesPersontracking, Featuresfacialrecognition, FeaturesShapeEstimation, FeaturesSpeedcalculation, FeaturesDistanceCalculation, IndustryPillcount, SolutionLossprevention, SolutionLoss1, SolutionLoss2, SolutionAssettracking, SolutionPillcount, Solutionpill1, Solutionpill2, SolutionAsset1, SolutionAsset2, SolutionSafetyandsecurity, SolutionSecurity1, SolutionSecurity2, SolutionVisionAnalytics, SolutionVision1, SolutionVision2, HealthcaretabImg, ManufacturingtabImg, RetailtabImg, EducationtabImg, CircleObjecttracking, CircleLossprevention, AiAttendance1, AiAttendance2, SolutionImageAalyis, SolutionProductCounting, SolutionQualityControl, SolutionWaitingtime, SolutionQualitycontrol1, SolutionQualitycontrol2, SolutionvisionSOC1, SolutionvisionSOC2, SolutionvisionSOCbanner, SolutionImageanalysis1, SolutionImageanalysis2,
        solution_healthcare, solution_healthcare_img1, solution_healthcare_img2, research_detail_img1, research_detail_img2, research_detail_img3, thumbnail_face_recognition, thumbnail_loss_prevention, thumbnail_shape_size, thumbnail_person_object_tracking, smartfridgeimg1, smartfridgeimg2, smartfridgeimg3,
        industry_loss_prevention, industry_vision_analytics, industry_asset_tracking, industry_ai_attendance, industry_safety_security, industry_quality_control, industry_object_product, industry_people_counting, industry_medical_image_analysis, industry_pill_count, industry_action_recognition,
        FeaturesActionRecognition, solution_action_recognition_img1, solution_action_recognition_img2, solution_action_recognition_img3, vision_analystics_img, thumbnail_vision_analytics, NVIDIADeepStream_img1, IntelDeepLearningStreamer_img1, EdgePlatformImg1, TestimonailImg1,
        icons: {
            icon1, icon2, icon3, icon4, icon5, icon6, icon7, web, mobile, vision, product, bulb, streamline, scale, promisesIcon1, promisesIcon2, promisesIcon3, aiml,
            IeducationIcon, IfinancialIcon, IhealthIcon, IretailIcon, quality, puzzle, performance, onTime, hello, html, uiux, webComputer, appdev, ampdev, development, User, BusinessService, growth, design,
            mobileApp, crossApp, nativeApp, appSecurity, appQuality, appSupport, Bot, deepLearning, machineLearning, AiMlIcon, cvVision, Annotation, dataAnalytics, dataForcsting, imageAnalytics, voiceAi, predictiveAnalytics, engine, planning, feasibility, feedback, approach, memory, security, bug, webLaptop, agileIcon, deploy,
            appleLogo, endUser, designTool, delivering, androidLogo, settingIdea, dartBoard, thirdParty, shortTdev, mobileNativeapps, meterialDesign, easyIntegration, hybridLogo, trendIcon, reactNativeLogo, costEstimate, bestReactNative, shareReactNative, productList, MEANicon1, MEANicon2, MEANicon3, MEANicon4, MEANlogo, MERNlogo, webAndapp, desktopDesign, lessTime, springBoot_logo, API, securityLaptop, legacy_icon, maintenance, tabCompanyicon, companyIcon,
            javaLogo, pythoLogo, pythonAPI, pythonComputer, pythonDev, magnifying, zeroCost, integrity, systemSettings, smartThinking, rocket_icon, communicationSkils, predictableCost, scalling, efficiency, staffAugmentation_logo, StaffAugmentationProcess, home_icon, tabAppsicon, tabBlogicon, tabHomeicon, tabProducticon, blogIcon, appsIcon, technologyIcon, domainIcon, duraionIcon, productIcon, quoteIcon, costSaving, collectIcon, annotateIcon, maintainIcon, monitorIcon, trainingIcon, connectIcon, deployIcon,
            LP_Solution_icon1, LP_Solution_icon2, LP_Solution_icon3, DeliveryModalIcon, EffortIcon, DomainbannerIcon, TechnologyIcon, InstagramIcon, LinkedinIcon, notification, alert,
            LP_Solution_detail_line_icon1, LP_Solution_detail_line_icon2, LP_Solution_detail_line_icon3, LP_Solution_detail_line_icon4, IndustryIcon, TwitterIcon, LinkedInIcon, InstaIcon, FdIcon, RetailIcon, DriveIcon, ManufacturingIcon, EducationIcon, HealthcareIcon,
            medicalanalysis, healthcare, aiicon, securitymeasureicon, trackingicon, facerecognition, identityverficationicon, materialdemandicon, dashboardicon, safetyverificationicon,
            ai, pillicon, losspreventionicon, playplugicon, medicineicon, qualitycontrolicon, stockicon, parkingicon, vehiclealerticon, retailsecurityicon, visionanalyticsicon,
            selfcheckouticon, safetysecurityicon, assettrackingicon, medicalimageicon,
        },

    }
}