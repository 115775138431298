import React, { useEffect, useState } from "react";
import { PATH } from "../globals/app-constants";
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Link } from "react-router-dom";


const Footer = () => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight, });
    const handleResize = () => {
        if (window.innerWidth > 768) {
            setShowAccordion({ accordion1: true, accordion2: true, accordion3: true, accordion4: true, accordion5: true, accordion6: true })
        } else {
            setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
        }
        setWindowSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight, });
    };
    useEffect(() => {
        if (window.screen.width > 768) {
            setShowAccordion({ accordion1: true, accordion2: true, accordion3: true, accordion4: true, accordion5: true, accordion6: true })
        }
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, [])
    const [showAccordion, setShowAccordion] = useState({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })

    const handleAccordion = (accordion) => {
        if (windowSize.windowWidth <= 768) {
            switch (accordion) {
                case 1:
                    setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                    break;
                case 2:
                    setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                    break;
                case 3:
                    setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false, accordion6: false })
                    break;
                case 4:
                    setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false, accordion6: false })
                    break;
                case 5:
                    setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5, accordion6: false })
                    break;
                case 6:
                    setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: !showAccordion.accordion6 })
                    break;
            }
        }
    }
    const copyRightContent = "Copyright ©" + new Date().getFullYear() + " Proglint. All Rights Reserved by Proglint";
    return (
        <>
            <footer className="bg-black">
                <div className="footer_top grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 container mx-auto mb-4 pt-8">
                    {/* <div className="border-b border-gray-200 md:border-none md:bg-transparent">
                        <div className="accordion-hedder py-3 flex justify-between items-center" onClick={() => handleAccordion(1)}>
                            <span className="fs-14 fB uppercase text-[#939393]">services</span>
                            <div className="w-5 h-5 md:hidden">
                                <ChevronDownIcon style={{color:"#fff"}} />
                            </div>
                        </div>
                        <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion1 && "hidden"} `}>
                            <div>
                                <ul>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/staff-augmentation-services')}>Staff Augmentation</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/java-full-stack-development-services')} >Java Full Stack</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/mern-stack-development-services')} >MERN Development</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/mean-stack-development-services')} >MEAN Development</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/react-native-app-development-services')}>React Native Development</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/python-development-services')} >Python Development</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                    <div className="border-b border-gray-200 md:border-none md:bg-transparent">
                        <div className="accordion-hedder py-3 flex justify-between items-center" onClick={() => handleAccordion(2)}>
                            <span className="fs-14 fB uppercase text-[#939393]">solutions</span>
                            <div className="w-5 h-5 md:hidden">
                                <ChevronDownIcon style={{color:"#fff"}} />
                            </div>
                        </div>
                        <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion2 && "hidden"} `}>
                            <div>
                                <ul>
                                {/* <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solution-loss-prevention')}>Loss Prevention</Link></li> */}
                                <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/loss-prevention')} >Loss Prevention</Link></li>
                                {/* <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions-loss-prevention')}>Loss Prevention in Healthcare</Link></li> */}
                                <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/pill-counting')} >Pill Counting</Link></li>
                                <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/vision-analytics')} >Vision Analytics</Link></li>
                                <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/asset-tracking')} >Asset Tracking</Link></li>
                                <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/safety-security')} >Safety and Security</Link></li>
                                <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/ai-attendance')} >AI Attendance</Link></li>
                                <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/ai-for-quality-control')} >AI for Quality Control</Link></li>
                                {/* <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/vision-selfcheckout')} >Vision Self Checkout</Link></li> */}
                                <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/medical-image-analysis')} >Medical Image Analysis</Link></li>
                                <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/action-recognition')} >Action Recognition</Link></li>
                                {/* <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solutions/smart-fridge')} >Smart Fridge</Link></li> */}
                                    {/* <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solution-unattended-retail')} >Unattended Retail</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/solution-operational-excellence')} >Operational Excellence</Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="border-b border-gray-200 md:border-none md:bg-transparent">
                        <div className="accordion-hedder py-3 flex justify-between items-center" onClick={() => handleAccordion(4)}>
                            <span className="fs-14 fB uppercase text-[#939393]">PLATFORMS</span>
                            <div className="w-5 h-5 md:hidden">
                                <ChevronDownIcon style={{color:"#fff"}} />
                            </div>
                        </div>
                        <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion4 && "hidden"} `}>
                            <div>
                                <ul>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/iOS-app-development-company')}>iOS</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/android-app-development-services')}>Android</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/web-development-services')}>Web Development</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/hybrid-app-development-services')}>Hybrid Platform</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-gray-200 md:border-none md:bg-transparent">
                        <div className="accordion-hedder py-3 flex justify-between items-center" onClick={() => handleAccordion(5)}>
                            <span className="fs-14 fB uppercase text-[#939393]">Docket</span>
                            <div className="w-5 h-5 md:hidden">
                                <ChevronDownIcon style={{color:"#fff"}} />
                            </div>
                        </div>
                        <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion5 && "hidden"} `}>
                            <div>
                                <ul>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/company')} >Company</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/career')}>Careers</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/blog')}>Blog</Link></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary" onClick={() => window.location.reload()}><Link to={('/portfolio')}>Portfolio</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-gray-200 md:border-none md:bg-transparent">
                        <div className="accordion-hedder transition-all duration-500 ease-in py-3 flex justify-between items-center" onClick={() => handleAccordion(6)}>
                            <span className="fs-14 fB uppercase text-[#939393]">CONTACT US</span>
                            <div className="w-5 h-5 md:hidden">
                                <ChevronDownIcon style={{color:"#fff"}} />
                            </div>
                        </div>
                        <div className={`accordion-body transition-all duration-500 ease-in ${!showAccordion.accordion6 && "hidden"} `}>
                            <div>
                                <ul>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary"><a href="mailto:info@proglint.com" target="_blank" rel="noreferrer">info@proglint.com</a></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary"><a href="https://www.proglint.com/" target="_blank" rel="noreferrer">www.proglint.com</a></li>
                                    <li className="fs-14 fR cursor-pointer text-white py-2 hover:text-secondary"><a href="tel:+917200012337" target="_blank" rel="noreferrer">+91-72000 12337</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:flex gap-32 mb-3 lg:mb-0 md:py-12 py-2 container mx-auto">
                    <div className="flex-1 ">
                        <button onClick={() => window.location.reload()}><Link to={('/')}><img src={PATH.img.logoWhite} alt="logo" className="w-24 md:w-32 mb-8" /></Link></button>
                        <h1 className="fs-18 fSB mb-3 text-white">Proglint Software Solutions</h1>
                        <p className="fs-14 fR text-white">Your Gateway to Advanced AI and Vision-Based Solutions, with App Development Services Serving Clients globally.</p>
                    </div>
                    <div className="flex-1">
                        <h1 className="fB fs-18 mb-8 text-white">Follow Us</h1>
                        <div className="flex gap-6">
                            <a href="https://www.linkedin.com/company/proglint-software-solutions/"
                                target="_blank" rel="noreferrer" className="focus:outline-none social-icon"><img src={PATH.img.linkedin} alt="linkedin" className="w-5 h-5" /></a>
                            <a href="https://www.instagram.com/proglint/" target="_blank" rel="noreferrer" className="focus:outline-none social-icon"><img src={PATH.img.instagram} alt="instagram" className="w-6 h-6" /></a>
                            <a href="https://www.facebook.com/proglint/" target="_blank" rel="noreferrer" className="focus:outline-none social-icon"><img src={PATH.img.facebook} alt="fb" className="w-7 h-7" /></a>
                            <a href="https://twitter.com/proglint" target="_blank" rel="noreferrer" className="focus:outline-none social-icon"><img src={PATH.img.twitter} alt="twitter" className="w-5 h-5" /></a>
                        </div>
                    </div>
                    {/* <div className="py-3 md:py-4 flex justify-center" onClick={() => window.location.reload()}>
                        <Link to={('/')} className="px-2 text-sm focus:outline-none text-pColor hover:text-secondary">Proglint Home</Link><span>|</span>
                        <Link to={('/contact')} className="px-2 text-sm focus:outline-none  text-pColor hover:text-secondary">Contact Us</Link><span className="hidden md:block">|</span>
                        <Link to={('/terms-of-service')} className="px-2 text-sm focus:outline-none text-pColor hidden md:block hover:text-secondary">Terms  of service</Link><span className="hidden md:block">|</span>
                        <Link to={('/privacy-policy')} className="px-2 text-sm focus:outline-none text-pColor hidden md:block hover:text-secondary">Privacy Policy</Link>
                    </div> */}
                </div>
                <div className="footer_bottom border-t border-[#595959] lg:flex justify-between container mx-auto pb-5 w-full py-5">
                    <div className="fs-14 fR flex flex-col lg:flex-row  gap-4 mb-2 lg:mb-0">
                        <Link to={('/contact')} className="fR fs-14 focus:outline-none  text-white hover:text-secondary">Contact Us</Link><span className="hidden md:block">|</span>
                        <Link to={('/terms-of-service')} className="fR fs-14 focus:outline-none text-white md:block hover:text-secondary">Terms  of service</Link><span className="hidden md:block">|</span>
                        <Link to={('/privacy-policy')} className="fR fs-14 focus:outline-none text-white md:block hover:text-secondary">Privacy Policy</Link>
                    </div>
                    
                    <p className="fR fs-14 text-white py-1">{copyRightContent}</p>
                </div>
            </footer>
            <script src="../asset/js/accordion.js"></script>
        </>
    )
}
export default Footer