import React from "react";
import FooterContact from "../../component/FooterContact";

const PrivacyPolicy = () => {


    return (
        <div>
            <div className="container mx-auto mt-32">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">Privacy Policy</h1>
                <p className="text-sm md:text-base text-pColor fR text-center">Welcome to Proglint Software Solutions Private Limited.</p>
                {/* <p className="text-sm md:text-base text-start text-pColor fR pt-5">Protecting your privacy is a priority, and this Privacy Policy is designed to provide detailed information on how we collect, use, disclose, and protect your personal information. By engaging with our software and services, you agree to the terms outlined in this privacy policy.</p> */}
                {/* <p className="text-sm md:text-base text-start text-pColor fR pt-5">This Privacy Policy outlines the data collection storage, and processing practices of Proglint Software Solutions (referred to as We, Our, or Us). It elucidates your rights regarding your data and provides on how to reach out to us with any queries.</p> */}
                <h3 className="text-xl fSB pt-5 mb-3">Information We collect:</h3>
                <p className="text-base fR text-pColor">Our computer vision products are designed to analyze video feeds and images for business insights without compromising individual privacy.</p>
                <p className="text-base fR pt-2">The information we collect may include:</p>
                <div className="pl-5 mt-1">
                    <li className="text-base fR"><b className="fSB">Visual Data:</b><span className="text-pColor px-2">Video feeds or images from cameras integrated with our solutions.</span></li>
                    <li className="text-base fR"><b className="fSB">Metadata:</b><span className="text-pColor px-2">Time, location, and device-specific information related to captured data.</span></li>
                    <li className="text-base fR"><b className="fSB">Feedback Data:</b><span className="text-pColor px-2">Voluntary information provided by users for system improvement.</span></li>
                    <p className="text-base fR mt-3"><b className="fSB">Note:</b><span className="text-pColor px-2">We do not collect personally identifiable information (PII) unless explicitly required and consented to.</span></p>
                </div>
                <h3 className="text-xl fSB pt-5 ">Purpose of Data Collection</h3>
                <p className="text-base fR pt-2">The data collected is used for:</p>
                <div className="pl-5">
                    <li className="text-base fR text-pColor">Generating business insights such as footfall analysis, heatmaps, or other analytics.</li>
                    <li className="text-base fR text-pColor">Enhancing product performance and accuracy.</li>
                    <li className="text-base fR text-pColor">Supporting troubleshooting and customer service.</li>
                </div>
                <h3 className="text-xl fSB pt-5 mb-3">Data Security</h3>
                <p className="text-base fR text-pColor">We implement industry-standard security measures to protect data from unauthorized access, alteration, or disclosure.</p>
                <p className="text-base fR pt-2">These include:</p>
                <div className="pl-5">
                    <li className="text-base fR text-pColor">Secure data transmission protocols (e.g., SSL/TLS).</li>
                    <li className="text-base fR text-pColor">Encrypted storage for sensitive information.</li>
                    <li className="text-base fR text-pColor">Access controls and regular audits of data handling practices.</li>
                </div>
                <h3 className="text-xl fSB pt-5 mb-3">Data Retention</h3>
                <div className="pl-5">
                    <li className="text-base fR text-pColor">Data is retained only as long as necessary to fulfill the purposes outlined in this policy or as required by law.</li>
                    <li className="text-base fR text-pColor">Visual data is anonymized or deleted wherever feasible to ensure privacy.</li>
                </div>
                <h3 className="text-xl fSB pt-5 mb-3">Sharing and Disclosure</h3>
                <p className="text-base fR text-pColor">Proglint does not sell or rent your data to third parties.</p>
                <p className="text-base fR pt-2">Data may be shared only:</p>
                <div className="pl-5">
                    <li className="text-base fR text-pColor">With consent from the customer.</li>
                    <li className="text-base fR text-pColor">With trusted partners or service providers under strict confidentiality agreements.</li>
                    <li className="text-base fR text-pColor">To comply with legal obligations or enforce our terms of service.</li>
                </div>
                <h3 className="text-xl fSB pt-5 mb-3">Your Rights</h3>
                <p className="text-base fR pt-2">You have the right to:</p>
                <div className="pl-5">
                    <li className="text-base fR text-pColor">Access, correct, or request deletion of your data.</li>
                    <li className="text-base fR text-pColor">Opt out of data collection or processing where applicable.</li>
                    <li className="text-base fR text-pColor">Lodge a complaint with a relevant data protection authority if you believe your rights have been violated.</li>
                </div>
                <h3 className="text-xl fSB pt-5 mb-3">Compliance with Privacy Regulations</h3>
                <p className="text-base fR pt-2">Proglint adheres to relevant privacy laws and regulations, such as:</p>
                <div className="pl-5">
                    <li className="text-base fR text-pColor">General Data Protection Regulation (GDPR).</li>
                    <li className="text-base fR text-pColor">California Consumer Privacy Act (CCPA).</li>
                    <li className="text-base fR text-pColor">Other applicable local and international privacy standards.</li>
                </div>
                <h3 className="text-xl fSB pt-5 mb-3">Updates to This Policy</h3>
                <p className="text-base text-pColor">We may update this privacy policy to reflect changes in our practices or regulatory requirements. Updates will be communicated through our website or directly to affected users.</p>
                <h3 className="text-xl fSB pt-5 mb-3">Contact Us:</h3>
                <p className="text-base fR text-pColor">If you have questions about this policy or our privacy practices, please contact us at:</p>
                <div className=" mb-11">
                    <p className="text-base fR text-pColor">Email: <a className="underline cursor-pointer fSB" href="mailto:info@proglint.com">support@proglint.com</a></p>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default PrivacyPolicy;